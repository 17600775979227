import { createStore, applyMiddleware, compose } from 'redux';

// middlewares
import { thunk } from 'redux-thunk'
// import logger from 'redux-logger'

// Import custom components
import rootReducer from '../reducers';
import axios from "axios";
import { fetchCats } from "../reducers/catsReducer";


function saveToLocalStorage(state) {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state', serializedState)
    } catch (e) {
    }
}

function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem('state')
        if (serializedState === null) return undefined
        return JSON.parse(serializedState)
    } catch (e) {
        return undefined
    }
}

const persistedState = loadFromLocalStorage()

/**
 * Create a Redux store that holds the app state.
 */
const store = createStore(rootReducer, persistedState, compose(
    applyMiddleware(thunk),

    //For working redux dev tools in chrome (https://github.com/zalmoxisus/redux-devtools-extension)
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : function (f) {
        return f;
    }
));

// eslint-disable-next-line no-unused-vars
const unsubscribe = store.subscribe(() => {
    const state = store.getState();
    saveToLocalStorage(state);
});


const _categories = axios.post(`${process.env.PUBLIC_URL}/users/fetchCategoryData`)
    .then((data) => {
        store.dispatch(fetchCats(data.data));
    })
    .catch(err => {
        return err.response;
    });

export default store;