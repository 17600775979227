import React, { Component, Fragment } from 'react';
import { Navigation, Box, MessageSquare, Users, Briefcase, CreditCard, ShoppingCart, Calendar } from 'react-feather';
import CountUp from 'react-countup';
import { Chart } from "react-google-charts";

import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { getClient } from './gatewayfunctions';

export class smsgateway extends Component {

    constructor(props) {
        super(props)

        this.state = {
            "client_name": '',
            "total_sms": '',
            "used_sms": '',
            "recharge_amount": '',
            "sms_rate": '',
            "recharge_date": '',
            "headers": ''
        }
    }

    componentDidMount() {

        var checkusertoken = localStorage.getItem('usertokenSatyamAdmin');
        if (checkusertoken == null) {
            this.props.history.push(`/`)
        }

        const client = {
            //"secret_key": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoic2F0eW1wXzczMTg0MyIsImlhdCI6MTYxNDg4ODc3MiwiZXhwIjoxNjE0OTA1OTcyfQ.QxuA5237cAYpp2WtkLXymZw1jPDUDvGgPAkRUrE9-hk'
            secret_key: "$2b$10$nlYUkMYllYRcYnIJ..dXXO1nmnnJT/1TyGXa5PIeCVJ1RFldaz13y"
        }

        getClient(client)
            .then((res) => {
                if (res.status == 'success') {
                    this.setState({
                        "client_name": res.data.clientData.client_name,
                        "total_sms": res.data.clientData.total_sms,
                        "used_sms": res.data.clientData.used_sms,
                        "recharge_amount": res.data.clientData.recharge_amount,
                        "sms_rate": res.data.clientData.sms_rate,
                        "recharge_date": res.data.rechargeData.recharge_date,
                        "headers": res.data.clientData.headers
                    })
                } else {
                    alert('Something Went Wrong ..!')
                }
            })
            .catch((err) => {
                alert('Something Went Wrong ..!')
            })
    }

    render() {
        return (

            <Fragment>
                <Breadcrumb title="SMS Gateway" parent="" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-3 col-md-6 xl-50">
                            <div className="card o-hidden widget-cards">
                                <div className="bg-secondary card-body">
                                    <div className="media static-top-widget row">
                                        <div className="icons-widgets col-4">
                                            <div className="align-self-center text-center"><Navigation className="font-secondary" /></div>
                                        </div>
                                        <div className="media-body col-8"><span className="m-0">Total Messages</span>
                                            <h3 className="mb-0"># <CountUp className="counter" end={this.state.total_sms} /><small> Remaining SMS: {this.state.total_sms - this.state.used_sms}   </small></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 xl-50">
                            <div className="card o-hidden  widget-cards">
                                <div className="bg-secondary card-body">
                                    <div className="media static-top-widget row">
                                        <div className="icons-widgets col-4">
                                            <div className="align-self-center text-center"><Box className="font-secondary" /></div>
                                        </div>
                                        <div className="media-body col-8"><span className="m-0">Recharge Amount</span>
                                            <h3 className="mb-0">₹ <CountUp className="counter" end={this.state.recharge_amount} /></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 xl-50">
                            <div className="card o-hidden widget-cards">
                                <div className="bg-secondary card-body">
                                    <div className="media static-top-widget row">
                                        <div className="icons-widgets col-4">
                                            <div className="align-self-center text-center"><MessageSquare className="font-secondary" /></div>
                                        </div>
                                        <div className="media-body col-8"><span className="m-0">Used Messages</span>
                                            <h3 className="mb-0"># <CountUp className="counter" end={this.state.used_sms} /></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 xl-50">
                            <div className="card o-hidden widget-cards">
                                <div className="bg-secondary card-body">
                                    <div className="media static-top-widget row">
                                        <div className="icons-widgets col-4">
                                            <div className="align-self-center text-center"><Users className="font-secondary" /></div>
                                        </div>
                                        <div className="media-body col-8"><span className="m-0">Message Rate</span>
                                            <h3 className="mb-0">₹ <CountUp className="counter" end={this.state.sms_rate} /><small> Paise</small></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </Fragment>

        )
    }
}
// javascript:void(0)

export default smsgateway
