import { Link } from 'react-router-dom';
import React, { Component, Fragment } from 'react'

import Breadcrumb from '../common/breadcrumb';
// import data from '../../assets/data/orders';
// import Datatable from '../common/datatable'

import { fetchOrdersAdmin } from './../ProductFunctions';

export class OrdersList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            orders: [],
        }
    }

    componentDidMount() {
        var checkusertoken = localStorage.getItem('usertokenSatyamAdmin');
        if (checkusertoken == null) {
            this.props.history.push(`/`)
        } else {
            fetchOrdersAdmin()
                .then(res => {
                    if (res.status == 200) {
                        const orders = res.data
                        this.setState({ orders: orders })
                    } else if (res.status === 401) {
                        this.setState({ orders: [] })
                    } else {
                        alert('1Error Occured.. Please Try after some time')
                    }
                })
                .catch((err) => {
                    alert('2Error Occured.. Please Try after some time')
                });
        }

    }

    // viewDetails = (e, orderid) => {
    //     this.props.history.push(`/order-detail/${orderid}`)
    // }

    render() {
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

        return (
            <Fragment>
                <Breadcrumb title="Orders" parent="Sales" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Manage Orders</h5>
                                </div>
                                <div className="card-body order-datatable lists-custom">
                                    {/* <Datatable
                                        multiSelectOption={false}
                                        myData={this.state.orders}
                                        pageSize={10}
                                        pagination={true}
                                        class="-striped -highlight"
                                    /> */}
                                    {this.state.orders.length > 0 ?
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Razorpay Order ID</th>
                                                    <th>Order Date</th>
                                                    {/* <th>Expected Delivery Date</th> */}
                                                    {/* <th>Notes</th> */}
                                                    <th>Address</th>
                                                    <th>Total</th>
                                                    <th>Status</th>
                                                    <th>Payment</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.orders.reverse().map((item, index) =>
                                                    <tr key={index}>
                                                        <td>{item.id}</td>
                                                        <td>{item.order_id}</td>
                                                        <td>{days[new Date(item.created).getDay()] + ', ' + monthNames[new Date(item.created).getMonth()] + ' ' + new Date(item.created).getDate() + ', ' + new Date(item.created).getFullYear()}</td>
                                                        {/* <td>{days[new Date((5 * 86400000) + item.created).getDay()] + ', ' + monthNames[new Date(item.delivery_date).getMonth()] + ' ' + new Date(item.delivery_date).getDate() + ', ' + new Date(item.delivery_date).getFullYear()}</td> */}
                                                        {/* <td>{item.notes}</td> */}
                                                        <td>
                                                            <p>
                                                                {item.firstname}&nbsp;{item.lastname}, <br />
                                                                {item.address},<br />
                                                                {item.city},&nbsp;{item.district} <br />
                                                                {item.state},&nbsp;{item.pincode}. <br />
                                                                {item.phone}<br />
                                                                {item.email}<br />
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <strong>
                                                                ₹&nbsp;{item.orderTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                            </strong>
                                                        </td>
                                                        <td>{item.status}</td>
                                                        {
                                                            item.payment_mode == 'COD' ?
                                                                <td>COD</td> :
                                                                <td style={{ color: item.payment_status == 'Pending' ? 'red' : 'green' }}>{item.payment_status}</td>
                                                        }
                                                        <td>
                                                            <Link to={`${process.env.PUBLIC_URL}/order-detail-${item.id}`}>
                                                                <i className="fa fa-arrow-circle-o-right" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table> :
                                        <div>
                                            <p>No order has been placed yet.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )

    }
}

export default OrdersList
