import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import Breadcrumb from '../../common/breadcrumb';
import Modal from 'react-responsive-modal';
import 'react-toastify/dist/ReactToastify.css';
import { fetchCategoryData, addSubCategory, editCategory, deleteSubCategory, addColor, fetchColor, updateColor, deleteColor, addVariant, fetchVariant, deleteVariant, updateVariant } from "../physical/product-functions/product-functions";
// import data from '../../../assets/data/category';
// import Datatable from '../../common/datatable';


export class ProductVariants extends Component {
    constructor(props) {
        super(props);
        this.state = {
            variantData: [],
            varaintToUpdateId: '',
            addorupdate: 'Add Variant',
            newVariant: '',
            searchPrds: [],
            selectedPrds: [],
            productsToUpdate: [],
            _searchText: ''
        };
    }

    componentDidMount() {
        const search = document.getElementById('search');
        const updateSearch = document.getElementById('updateSearch');

        search.addEventListener('input', () => this.searchProducts(search.value));
        updateSearch.addEventListener('input', () => this.searchProducts(updateSearch.value));

        var checkusertoken = localStorage.getItem('usertokenSatyamAdmin');
        if (checkusertoken == null) {
            this.props.history.push(`/`)
        } else {
            fetchVariant()
                .then(res => {
                    if (res.status == 200) {
                        var productsToShow = []
                        var variantData = res.data.data

                        if (variantData[0]) {
                            for (var i = 0; i < variantData[0].products.length; i++) {
                                var item = variantData[0].products[i]
                                productsToShow.push(this.findPrdFromList(item))
                            }
                            this.setState({
                                variantData: variantData,
                                varaintToUpdateId: variantData[0].name,
                                productsToUpdate: productsToShow
                            })
                        }
                    } else {
                        alert('Error occured.. Please try after some time')
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alert(err)
                })
        }
    }

    addVariantFn = (e) => {
        e.preventDefault();
        if (window.confirm('Add Variant?')) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            const data = {
                name: this.state.newVariant,
                products: this.state.selectedPrds
            }
            addVariant(data)
                .then(res => {
                    if (res.data.status == 200) {
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.data.status == 400) {
                        alert(res.data.msg)
                        spinner.style.display = "none";
                    } else {
                        alert('Error occured while adding variant.. Please try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error occured while adding varaint.. Please try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    updateVariantFn = (e) => {
        e.preventDefault();
        if (window.confirm('Update Variant?')) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            const data = {
                name: this.state.varaintToUpdateId,
                products: this.state.selectedPrds,
            }
            updateVariant(data)
                .then(res => {
                    if (res.data.status == 200) {
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.data.status == 400) {
                        alert(res.data.msg)
                        spinner.style.display = "none";
                    } else {
                        alert('Error occured while updating variant. Please try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error occured while updating variant. Please try after some time1')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    deleteVariantFn = (e, variant) => {
        e.preventDefault();
        if (window.confirm('Delete Variant?')) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            const data = { name: variant }
            deleteVariant(data)
                .then(res => {
                    if (res.data.status == 200) {
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.data.status == 400) {
                        alert(res.data.msg)
                        spinner.style.display = "none";
                    } else {
                        alert('Error occured while deleting variant. Please try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error occured while deleting variant. Please try after some time1')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    searchProducts = async searchText => {
        this.setState({
            _searchText: searchText
        })
        const products = this.props.products
        let matches = products.filter(product => {
            const regex = new RegExp(`^${searchText}`, 'gi');
            return product.sku.match(regex) || product.name.match(regex);
        });
        const matchList = document.getElementById('match-list');
        if (searchText.length === 0) {
            matches = [];
            this.setState({
                searchPrds: []
            })
        } else {
            this.setState({
                searchPrds: matches
            })
        }
    }

    findPrdFromList = (id) => {
        var products = this.props.products
        var product = {}
        for (var i = 0; i < products.length; i++) {
            var item = products[i]
            if (item.id == id) {
                product = item
                break
            }
        }
        return product
    }

    findVariantFromList = (name) => {
        var variants = this.state.variantData
        var variant = {}
        for (var i = 0; i < variants.length; i++) {
            var item = variants[i]
            if (item.name == name) {
                variant = item
                break
            }
        }
        return variant
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Product Variants" parent="Shop" />
                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Add or Update Variants</h5>
                                </div>
                                <div className="card-header">
                                    <select className="form-control" id="addorupdate" name="addorupdate" value={this.state.addorupdate} onChange={(e) => this.setState({ addorupdate: e.target.value, selectedPrds: this.state.productsToUpdate })} style={{ maxWidth: '250px' }}>
                                        <option value="Add Variant">Add Variant</option>
                                        <option value="Update Variant">Update Variant</option>
                                    </select>
                                    <hr />

                                    <div style={{ display: this.state.addorupdate == 'Add Variant' ? '' : 'none' }}>
                                        <h5>Add Variant</h5>
                                        <form onSubmit={this.addVariantFn}>
                                            <label className='mt-3'>New Variant : </label>
                                            <input style={{ maxWidth: '350px' }} className="form-control" id="newVariant" name="newVariant" onChange={(e) => this.setState({ newVariant: e.target.value })} required />
                                            <label className='mt-3'>Search Product : </label>
                                            <div style={{ maxWidth: '350px', display: 'flex', flexDirection: 'row' }}>
                                                <input type="text" className="form-control" id="search" placeholder="Search product..." value={this.state._searchText} />
                                                <div style={{ padding: '5px 10px 5px 10px', margin: '2px 2px 2px 10px', cursor: 'pointer', borderRadius: '5px', backgroundColor: '#b99c45', color: '#ffffff', fontWeight: 'bold', display: 'flex', alignItems: 'center' }} onClick={(e) => {
                                                    this.setState({
                                                        _searchText: '',
                                                        searchPrds: [],
                                                    })
                                                }}>
                                                    Clear
                                                </div>
                                            </div>
                                            <div>
                                                {
                                                    this.state.searchPrds.map((product, idx) => {
                                                        var isChecked = false;
                                                        for (var i = 0; i < this.state.selectedPrds.length; i++) {
                                                            if (this.state.selectedPrds[i].name === product.name && this.state.selectedPrds[i].sku === product.sku && this.state.selectedPrds[i].price === product.price) {
                                                                isChecked = true;
                                                                break;
                                                            }
                                                        }
                                                        return (
                                                            <div key={idx} onClick={(e) => {
                                                                if (isChecked) {
                                                                    this.setState({
                                                                        selectedPrds: this.state.selectedPrds.filter((_, i) => {
                                                                            if (_.id !== product.id && _.sku !== product.sku) {
                                                                                return product;
                                                                            }
                                                                        })
                                                                    })
                                                                } else {
                                                                    this.setState({ selectedPrds: [...this.state.selectedPrds, product] })
                                                                }
                                                            }} className="card card-body mb-2" style={{ padding: '15px', maxWidth: '450px', backgroundColor: '#f7f7f9', cursor: 'pointer', border: isChecked ? '2px solid #1A1460' : '0px' }} >
                                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                    <div style={{ margin: '0px 10px 0px 0px', borderRadius: '8px' }}>
                                                                        <img
                                                                            className="img-fluid blur-up bg-img lazyloaded" style={{ maxHeight: '60px', maxWidth: '40px' }}
                                                                            src={`${process.env.REACT_APP_BASE_URL}${product.image[0]}`}
                                                                        />
                                                                    </div>
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <div >SKU : {product.sku}</div>
                                                                        <small>Name : {product.name}</small>
                                                                        <small>Price : ₹{product.price}</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className="card-body order-datatable lists-custom">
                                                {
                                                    this.state.selectedPrds.length ?
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Sr. No.</th>
                                                                    <th>Id</th>
                                                                    <th>Image</th>
                                                                    <th>SKU</th>
                                                                    <th>Name</th>
                                                                    <th>Remove Product</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.selectedPrds.map((item, index) =>
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{item.id}</td>
                                                                        <td>
                                                                            {
                                                                                <img
                                                                                    className="img-fluid blur-up bg-img lazyloaded" style={{ maxHeight: '60px', maxWidth: '40px' }}
                                                                                    src={`${process.env.REACT_APP_BASE_URL}${item.image[0]}`}
                                                                                />
                                                                            }</td>
                                                                        <td>{item.sku}</td>
                                                                        <td style={{ textAlign: 'start' }}>{item.name}</td>
                                                                        <td>
                                                                            <a onClick={(e) => {
                                                                                this.setState({
                                                                                    selectedPrds: this.state.selectedPrds.filter((_, i) => {
                                                                                        if (_.id !== item.id && _.sku !== item.sku) {
                                                                                            return item;
                                                                                        }
                                                                                    })
                                                                                })
                                                                            }}><i className="fa fa-times" /></a>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                        :
                                                        <div>
                                                            <p>No product has been added yet.</p>
                                                        </div>
                                                }
                                            </div>
                                            <button className="btn btn-primary mt-3">
                                                Add
                                            </button>
                                        </form>
                                    </div>
                                    <div style={{ display: this.state.addorupdate == 'Update Variant' ? '' : 'none' }}>
                                        <h5>Update Variant</h5>
                                        <form onSubmit={this.updateVariantFn}>
                                            <label className='mt-3'>Select Variant : </label>
                                            <select
                                                required
                                                className="form-control mb-0"
                                                name="addInCategory"
                                                id="addInCategory"
                                                onChange={(e) => {
                                                    var productsToShow = []
                                                    for (var i = 0; i < this.findVariantFromList(e.target.value).products.length; i++) {
                                                        var item = this.findVariantFromList(e.target.value).products[i]
                                                        productsToShow.push(this.findPrdFromList(item))
                                                    }
                                                    this.setState({
                                                        varaintToUpdateId: e.target.value,
                                                        selectedPrds: productsToShow
                                                    })

                                                }}
                                            >
                                                {
                                                    this.state.variantData ?
                                                        this.state.variantData.map((variantObj) => {
                                                            return <option value={variantObj.name}>{variantObj.name}</option>;
                                                        })
                                                        : ""
                                                }
                                            </select>
                                            <label className='mt-3'>Search Product : </label>
                                            <div style={{ maxWidth: '350px', display: 'flex', flexDirection: 'row' }}>
                                                <input type="text" id='updateSearch' className="form-control" placeholder="Search product..." value={this.state._searchText} />
                                                <div style={{ padding: '5px 10px 5px 10px', margin: '2px 2px 2px 10px', cursor: 'pointer', borderRadius: '5px', backgroundColor: '#b99c45', color: '#ffffff', fontWeight: 'bold', display: 'flex', alignItems: 'center' }} onClick={(e) => {
                                                    this.setState({
                                                        _searchText: '',
                                                        searchPrds: [],
                                                    })
                                                }}>
                                                    Clear
                                                </div>
                                            </div>
                                            <div>
                                                {
                                                    this.state.searchPrds.map((product, idx) => {
                                                        var isChecked = false;
                                                        for (var i = 0; i < this.state.selectedPrds.length; i++) {
                                                            if (this.state.selectedPrds[i].name === product.name && this.state.selectedPrds[i].sku === product.sku && this.state.selectedPrds[i].price === product.price) {
                                                                isChecked = true;
                                                                break;
                                                            }
                                                        }
                                                        return (
                                                            <div key={idx} onClick={(e) => {
                                                                if (isChecked) {
                                                                    this.setState({
                                                                        selectedPrds: this.state.selectedPrds.filter((_, i) => {
                                                                            if (_.id !== product.id && _.sku !== product.sku) {
                                                                                return product;
                                                                            }
                                                                        })
                                                                    })
                                                                } else {
                                                                    this.setState({ selectedPrds: [...this.state.selectedPrds, product] })
                                                                }
                                                            }} className="card card-body mb-2" style={{ padding: '15px', maxWidth: '450px', backgroundColor: '#f7f7f9', cursor: 'pointer', border: isChecked ? '2px solid #1A1460' : '0px' }} >
                                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                    <div style={{ margin: '0px 10px 0px 0px', borderRadius: '8px' }}>
                                                                        <img
                                                                            className="img-fluid blur-up bg-img lazyloaded" style={{ maxHeight: '60px', maxWidth: '40px' }}
                                                                            src={`${process.env.REACT_APP_BASE_URL}${product.image[0]}`}
                                                                        />
                                                                    </div>
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <div >SKU : {product.sku}</div>
                                                                        <small>Name : {product.name}</small>
                                                                        <small>Price : ₹{product.price}</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className="card-body order-datatable lists-custom">
                                                {
                                                    this.state.selectedPrds.length > 0 ?
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Sr. No.</th>
                                                                    <th>Id</th>
                                                                    <th>Image</th>
                                                                    <th>SKU</th>
                                                                    <th>Name</th>
                                                                    <th>Remove Product</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.selectedPrds.map((item, index) =>
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{item.id}</td>
                                                                        <td>
                                                                            {
                                                                                <img
                                                                                    className="img-fluid blur-up bg-img lazyloaded" style={{ maxHeight: '60px', maxWidth: '40px' }}
                                                                                    src={`${process.env.REACT_APP_BASE_URL}${item.image[0]}`}
                                                                                />
                                                                            }</td>
                                                                        <td>{item.sku}</td>
                                                                        <td style={{ textAlign: 'start' }}>{item.name}</td>
                                                                        <td>
                                                                            <a onClick={(e) => {
                                                                                this.setState({
                                                                                    selectedPrds: this.state.selectedPrds.filter((_, i) => {
                                                                                        if (_.id !== item.id && _.sku !== item.sku) {
                                                                                            return item;
                                                                                        }
                                                                                    })
                                                                                })
                                                                            }}><i className="fa fa-times" /></a>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                        :
                                                        <div>
                                                            <p>No product has been added yet.</p>
                                                        </div>
                                                }
                                            </div>
                                            <button type="submit" className="btn btn-primary mt-3">
                                                Update
                                            </button>
                                        </form>
                                    </div>
                                    <hr />
                                    <div className="card-body order-datatable lists-custom">
                                        {
                                            this.state.variantData.length > 0 ?
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Sr. No.</th>
                                                            <th>Variant</th>
                                                            <th>Products</th>
                                                            <th>Delete Variant</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.variantData.map((item, index) =>
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item.name}</td>
                                                                    <td style={{ textAlign: 'start' }}>
                                                                        {
                                                                            item.products.map((productId, idx) =>
                                                                                <div key={idx} >
                                                                                    <a style={{ color: 'black', fontWeight: 'lighter', fontSize: '10px' }} href={`${process.env.PUBLIC_URL}/products/physical/product-detail/${productId}`}>
                                                                                        {
                                                                                            `${idx + 1}. ${(this.findPrdFromList(productId)).name} (${productId})`
                                                                                        }
                                                                                    </a>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <a href='#' onClick={(e) => this.deleteVariantFn(e, item.name)}><i className="fa fa-times" /></a>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                    </tbody>
                                                </table>
                                                :
                                                <div>
                                                    <p>No variant has been added yet.</p>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </Fragment >
        )
    }
}
const mapStateToProps = (state) => ({
    products: state.data.products,
    symbol: state.data.symbol,
})

export default connect(mapStateToProps)(ProductVariants);