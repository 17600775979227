import React, { Component, Fragment } from 'react'

import Breadcrumb from '../common/breadcrumb';

import { fetchSingleReturnExchangeRequest, updateReturnExchangeRequest } from './../../api/order'

export class ReturnExchangeRequestDetailed extends Component {

    constructor(props) {
        super(props)

        this.state = {
            id: '',
            images: [],
            items: [],
            order_details: "",
            order_id: "",
            request: "",
            status: "",
            temp_status: "",
            user: "",
            date: '',
            plan: '',
            refund: 0,
        }
    }

    componentDidMount() {
        var checkusertoken = localStorage.getItem('UTShivshahiAdmin');
  
        if (checkusertoken == null) {
            this.props.history.push(`/`)
        } else {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const requestDetails = {
                id: this.props.match.params.slug,
            }

            // fetchSingleReturnExchangeRequest(requestDetails)
            //     .then(res => {
            //         if (res.status == 200) {
            //             const singleRequest = res.data

            //             var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
            //             var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
            //             var date = new Date(singleRequest.date);
            //             var dateFinal = days[date.getDay()] + ', ' + monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();

            //             this.setState({
            //                 id: singleRequest.id,
            //                 date: dateFinal,
            //                 images: JSON.parse(singleRequest.images),
            //                 items: JSON.parse(singleRequest.items),
            //                 order_details: JSON.parse(singleRequest.order_details),
            //                 order_id: singleRequest.order_id,
            //                 request: singleRequest.request,
            //                 status: singleRequest.status,
            //                 temp_status: singleRequest.status,
            //                 user: JSON.parse(singleRequest.user),
            //                 plan: JSON.parse(JSON.parse(singleRequest.order_details).profits)[0].scheme
            //             }, () => {
            //                 var totalQty1 = 0;
            //                 this.state.items.map((item, index) => {
            //                     totalQty1 = parseInt(item.reQty) + parseInt(totalQty1)
            //                 })

            //                 if (this.state.plan == 'Business Opportunity') {
            //                     // if (this.state.order_details.state == "Maharashtra") {
            //                     if (totalQty1 == 1 || totalQty1 == 2) {
            //                         this.setState({ refund: 100 })
            //                     } else {
            //                         this.setState({ refund: 100 + ((parseInt(totalQty1) - 2) * 50) })
            //                     }
            //                     // } else {
            //                     //     if (totalQty1 == 1) {
            //                     //         this.setState({ refund: 150 })
            //                     //     } else if (totalQty1 == 2) {
            //                     //         this.setState({ refund: 250 })
            //                     //     } else {
            //                     //         this.setState({ refund: 250 + ((parseInt(totalQty1) - 2) * 100) })
            //                     //     }
            //                     // }
            //                 } else {
            //                     this.setState({ refund: 0 })
            //                 }

            //                 spinner.style.display = "none";
            //             })
            //         } else {
            //             alert('Something Went Wrong')
            //             this.props.history.push(`${process.env.PUBLIC_URL}/requests/returnexchange`)
            //         }
            //     })
            //     .catch((err) => {
            //         alert('Something Went Wrong')
            //         this.props.history.push(`${process.env.PUBLIC_URL}/requests/returnexchange`)
            //     })
        }

    }

    viewImages = () => {
        this.state.images.map((image, index) => {
            window.open(`https://www.shivshahinetwork.com/assets/images/return-exchange-images/${image}`);
        })
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    // statusChange = (e) => {
    //     e.preventDefault();

    //     let spinner = document.getElementById("spinneranimid");
    //     spinner.style.display = "block";

    //     const requestData = {
    //         id: this.state.id,
    //         status: this.state.temp_status,
    //         order_id: this.state.order_details.id,
    //         plan: this.state.plan,
    //         amount: this.state.refund,
    //         userid: this.state.user.id
    //     }

    //     if (this.state.temp_status != this.state.status && this.state.status != 'Completed') {
    //         if (window.confirm('Do you want to update the status ?')) {
    //             updateReturnExchangeRequest(requestData)
    //                 .then((res) => {
    //                     if (res.status == 200) {
    //                         alert('Status Updated ..!')
    //                         window.location.reload()
    //                         spinner.style.display = "none";
    //                     } else {
    //                         alert('Something Went Wrong ..!')
    //                         window.location.reload()
    //                         spinner.style.display = "none";
    //                     }
    //                 })
    //                 .catch((err) => {
    //                     alert('Something Went Wrong ..!')
    //                     window.location.reload()
    //                     spinner.style.display = "none";
    //                 })
    //         } else {
    //             spinner.style.display = "none";
    //         }
    //     } else {
    //         spinner.style.display = "none";
    //     }
    // }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Return & Exchange" parent="Requests" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Request:&nbsp;&nbsp;<strong style={{ color: '#c96' }}>{this.state.request}</strong></h5>
                                </div>
                                <div className="card-body order-datatable lists-custom">

                                    <div className="custom-order-details">
                                        <h4>Request Details</h4>
                                        <ul>
                                            <li>Order ID :&nbsp;&nbsp;<span>{parseInt(this.state.order_details.id) + 9999}</span></li>
                                            <li>Date :&nbsp;&nbsp;<span>{this.state.date}</span></li>
                                        </ul>

                                        <h4 className='mt-4 mb-2'>Defective Images</h4>
                                        <div><strong style={{ fontSize: '15px', color: '#c96', cursor: 'pointer' }} onClick={this.viewImages}>Click Here</strong></div>

                                        <hr />
                                        <h4>Requested Items</h4>
                                        <div className="mb-4">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className='first'>Product</th>
                                                        <th>Price</th>
                                                        <th>Size</th>
                                                        <th>Sku</th>
                                                        <th>Color</th>
                                                        <th>Quantity</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.items.map((item, index) =>
                                                        <tr key={index}>
                                                            <td className='first'>
                                                                <a href={`${process.env.PUBLIC_URL}/products/product-detail/${item.id}`} target="_blank" rel="noopener noreferrer">
                                                                    <figure>
                                                                        {
                                                                            item.pictures.map((picture, index2) =>
                                                                                <img key={index2} style={{ width: '100px' }} src={`https://www.shivshahinetwork.com/${picture}`} alt="Product" />
                                                                            )
                                                                        }
                                                                    </figure>
                                                                    <h5 className="product-title pl-5">
                                                                        {item.name}
                                                                    </h5>
                                                                </a>
                                                            </td>

                                                            <td>
                                                                ₹&nbsp;
                                                            {item.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                            </td>

                                                            <td>{item.size} Yards</td>

                                                            <td><strong>{item.sku}</strong></td>

                                                            <td><div className="color-display" style={{ backgroundColor: item.color }} /></td>

                                                            <td>{item.reQty}</td>

                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <hr />
                                        <div className="order-statuss">

                                            <form onSubmit={this.statusChange}>

                                                <h4>Refundable Amount : <strong>₹ {this.state.refund}</strong></h4>
                                                <h4 style={{ paddingTop: '5px' }}>Plan : <strong>{this.state.plan}</strong></h4>

                                                <h4>Current Status : <strong>{this.state.status}</strong></h4>
                                                <select className="form-control" id="statuschange" name="temp_status" value={this.state.temp_status} onChange={this.onChange}>
                                                    <option value="Requested">Requested</option>
                                                    <option value="Accepted">Accepted</option>
                                                    <option value="Product Received">Product Received</option>
                                                    <option value="Processed">Processed</option>
                                                    {/* <option value="Packed" style={{ display: this.state.request == 'Exchange' ? '' : 'none' }}>Packed</option> */}
                                                    <option value="Shipped" style={{ display: this.state.request == 'Exchange' ? '' : 'none' }}>Shipped</option>
                                                    <option value="Delivered" style={{ display: this.state.request == 'Exchange' ? '' : 'none' }}>Delivered</option>
                                                    <option value="Completed">Completed</option>
                                                    <option value="Cancelled">Cancelled</option>
                                                    <option value="Rejected">Rejected</option>
                                                </select>

                                                <button type="submit" className="btn btn-primary mt-3">
                                                    Update Request
                                                </button>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Fragment >
        )

    }
}

export default ReturnExchangeRequestDetailed
