import { combineReducers } from 'redux';
//import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual'

// Import custom components
import productReducer from './products';
import filtersReducer from './filters';
import catsReducer from './catsReducer'


const rootReducer = combineReducers({
    data: productReducer,
    filters: filtersReducer,
    catsData: catsReducer,
    Intl
});

export default rootReducer;