import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './index.scss';
import App from './components/app';
//import { ScrollContext } from 'react-router-scroll-4';

//React_Redux Store
import store from "./store";
import { getAllProducts } from "./actions";

// Components
import Dashboard from './components/dashboard';

// Products physical
import Category from './components/products/physical/category';
import Sub_category from './components/products/physical/sub-category';
import ProductList from './components/products/physical/product-list';
import AddProduct from './components/products/physical/add-product';
import ProductDetail from './components/products/physical/product-detail';
import EditProduct from './components/products/physical/edit-product';

//Sales
import Orders from './components/sales/orders';
import OrdersList from './components/sales/orders-list';
import OrderDetails from './components/sales/order-detail';
import Transactions_sales from './components/sales/transactions-sales';
// //Coupons

//Pages
import ListPages from './components/pages/list-page';
import Create_page from './components/pages/create-page';

import { ResellerList } from './components/resellers/ResellerList';
import { AddReseller } from './components/resellers/addreseller';

import Profile from './components/settings/profile';
import Login from './components/auth/login';

import ShowOffGallery from './components/utilities/ShowOffGallery'
import Testimonials from './components/utilities/NewTestimonial'
import AddLinks from './components/utilities/manageLinks'
import smsgateway from './components/sms/smsgateway'
import UserList from './components/userdata/UserList'

import WithdrawlRequests from './components/requests/withdrawlRequests';
import ReturnExchangeRequests from './components/requests/returnExchangeRequests';
import ReturnExchangeRequestDetailed from './components/requests/returnExchangeRequestDetailed'

import Transactions from './components/sales/transactions';
import couponCodes from './components/resellers/couponCodes';
import ManageColors from './components/products/physical/manage-colors';
import ProductVariants from './components/products/physical/product-variants';
import DailyDeals from './components/utilities/daily-deals';
import PriceTags from './components/products/physical/price-tags';
import { LandingPageBanner } from './components/utilities/landingPageBanner';


class Root extends Component {
    render() {
        store.dispatch(getAllProducts());
        return (
            <Provider store={store}>
                <BrowserRouter basename={'/'}>
                    {/* <ScrollContext> */}
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Login} />
                        <Route exact path={`${process.env.PUBLIC_URL}/auth/login`} component={Login} />
                        <App>
                            <Route path={`${process.env.PUBLIC_URL}/dashboard`} component={Dashboard} />
                            <Route path={`${process.env.PUBLIC_URL}/category`} component={Category} />
                            <Route path={`${process.env.PUBLIC_URL}/products/physical/sub-category`} component={Sub_category} />
                            <Route path={`${process.env.PUBLIC_URL}/products/physical/product-list`} component={ProductList} />
                            <Route path={`${process.env.PUBLIC_URL}/manage-colors`} component={ManageColors} />
                            <Route path={`${process.env.PUBLIC_URL}/manage-price-tags`} component={PriceTags} />
                            <Route path={`${process.env.PUBLIC_URL}/products/physical/product-detail/:id`} component={ProductDetail} />
                            <Route path={`${process.env.PUBLIC_URL}/add-product`} component={AddProduct} />
                            <Route path={`${process.env.PUBLIC_URL}/update-product/:id`} component={EditProduct} />
                            <Route path={`${process.env.PUBLIC_URL}/products/variants`} component={ProductVariants} />
                            <Route path={`${process.env.PUBLIC_URL}/orders`} component={OrdersList} />
                            <Route path={`${process.env.PUBLIC_URL}/order-detail-:slug`} component={OrderDetails} />
                            <Route path={`${process.env.PUBLIC_URL}/transactions`} component={Transactions} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/list-page`} component={ListPages} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/create-page`} component={Create_page} />
                            <Route path={`${process.env.PUBLIC_URL}/settings/profile`} component={Profile} />
                            <Route path={`${process.env.PUBLIC_URL}/resellers/list_resellers`} component={ResellerList} />
                            <Route path={`${process.env.PUBLIC_URL}/resellers/add-resellers`} component={AddReseller} />
                            <Route path={`${process.env.PUBLIC_URL}/coupon-codes`} component={couponCodes} />
                            <Route exact path={`${process.env.PUBLIC_URL}/requests/withdrawl`} component={WithdrawlRequests} />
                            <Route exact path={`${process.env.PUBLIC_URL}/requests/returnexchange`} component={ReturnExchangeRequests} />
                            <Route path={`${process.env.PUBLIC_URL}/requests/return-exchange-request-detail:slug`} component={ReturnExchangeRequestDetailed} />
                            <Route path={`${process.env.PUBLIC_URL}/utilities/show-offgallery`} component={ShowOffGallery} />
                            <Route path={`${process.env.PUBLIC_URL}/utilities/testimonials`} component={Testimonials} />
                            <Route path={`${process.env.PUBLIC_URL}/utilities/daily-deals`} component={DailyDeals} />
                            <Route path={`${process.env.PUBLIC_URL}/utilities/landing-page-banner`} component={LandingPageBanner} />
                            <Route path={`${process.env.PUBLIC_URL}/smsgateway/`} component={smsgateway} />
                            <Route path={`${process.env.PUBLIC_URL}/utilities/add-links`} component={AddLinks} />
                            <Route path={`${process.env.PUBLIC_URL}/user-list`} component={UserList} />
                        </App>
                    </Switch>
                    {/* </ScrollContext> */}
                </BrowserRouter>
            </Provider>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));