import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import Breadcrumb from "../../common/breadcrumb";
import { Edit, Trash2, Upload, CheckCircle, Eye } from "react-feather";
import StarRatingComponent from "react-star-rating-component";
import { deleteProduct, updateProduct } from "../../../api/product";
import { deletetMultipleProducts } from "./product-functions/product-functions";
export class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navMenus: false,
      searchPrds: this.props.products,
      selectedPrdsToDelete: []
    };
  }

  componentDidMount() {
    this.setState({ open: true });
    const search = document.getElementById('search');
    search.addEventListener('input', () => this.searchProducts(search.value));
  }

  // Search Products
  searchProducts = async searchText => {
    const products = this.props.products

    if (searchText == "") {
      this.setState({
        searchPrds: products
      })
    } else {
      let matches = products.filter(product => {
        const regex = new RegExp(`^${searchText}`, 'gi');
        return product.sku.match(regex) || product.name.match(regex);
      });

      const matchList = document.getElementById('match-list');
      if (searchText.length === 0) {
        matches = [];
        matchList.innerHTML = '';
      } else {
        this.setState({
          searchPrds: matches
        })
      }

      const outputHtml = matches => {
        if (matches.length > 0) {
          const html = matches.map(match => `
        <a href="${process.env.PUBLIC_URL}/products/physical/product-detail/${match.id}">
				  <div class="card card-body mb-2" >
            <div style="display:flex;flex-direction:row;">
              <div style="margin:0px 10px 0px 0px">
                <img
                className="img-fluid blur-up bg-img lazyloaded" style="max-width: 40px;max-height:60px" 
                src="${process.env.REACT_APP_BASE_URL}${match.image[0]}"
                />
              </div>
              <div>
                <h4>SKU:${match.sku}</h4> 
					      <small>Name:${match.name}</small>
                <small>Price:₹${match.price}</small>
              </div>
            </div>
				  </div>
        </a>
				`)
            .join('');
          matchList.innerHTML = html
        }
      }

      //outputHtml(matches);
    }
  };

  // Show Results in HTML
  // outputHtml = matches => {
  //   if (matches.length > 0) {
  //     const html = matches.map(match => `
  // 		 <div class="card card-body mb-4">
  // 		  <h4>${match.name} (${match.description}) ,span class="text-primary">
  // 			  ${match.price}</span></h4>
  // 			  <small> ${match.categories}</small>
  // 		 </div>
  // 		`)

  //   }
  // }

  openSearch() {
    document.getElementById("search-overlay").style.display = "block";
  }

  closeSearch() {
    document.getElementById("search-overlay").style.display = "none";
  }

  search() {
    document.getElementById('search');

  }

  matchList() {
    document.getElementById('match-list')
  }

  deleteItem = async (e, id, image) => {
    const productData = {
      Itemid: id,
      image: image
    };

    if (window.confirm('Delete this Item?')) {
      deleteProduct(productData)
        .then(res => {
          if (res.status == 200) {
            alert('Product Item Deleted. Click ok to refresh.')
            this.props.history.push('/products/physical/product-list');
            window.location.reload();
          } else {
            alert('Error while deleting. Please Try after some time')
          }
        })
        .catch((err) => {
          alert('Error while deleting product. Please try after some time')
          window.location.reload();
        })
    }
  };

  // emptyStock = async (e, data) => {
  //   const productData = {
  //     id: data.id,
  //     name: data.name,
  //     price: data.price,
  //     discount: data.discount,
  //     resellerPrice: data.resellerPrice,
  //     resellerDiscount: data.resellerDiscount,
  //     color: data.variation[0].color,
  //     category: data.category,
  //     subcategory: data.subcategory,
  //     rating: data.rating,
  //     stock6: 0,
  //     stock9: 0,
  //     stock11: 0,
  //     weight: data.Weight,
  //     material: data.Materials,
  //     blousepiece: data.BlousePiece,
  //     fabriccare: data.FabricCare,
  //     content: data.fullDescription,
  //     imageFiles: data.image
  //   };

  //   // const Itemid = id
  //   if (window.confirm('Empty Stock?')) {
  //     updateProduct(productData).then((res) => {
  //       if (res) {
  //         this.props.history.push('/products/physical/product-list');
  //         //window.location.reload();
  //       }
  //     });
  //   }
  // };

  linkToUpdatePage = async (e, id) => {
    if (id) {
      this.props.history.push(
        `${process.env.PUBLIC_URL}/update-product/${id}`
      )
    }
  }

  deleteSelectedProducts = () => {
    var productsToDelete = this.state.selectedPrdsToDelete
    var prdsString = ''
    for (var i = 0; i < productsToDelete.length; i++) {
      if (i == (productsToDelete.length - 1)) {
        prdsString = prdsString + (i + 1) + '. ' + productsToDelete[i].name
      } else {
        prdsString = prdsString + (i + 1) + '. ' + productsToDelete[i].name + '\n'
      }
    }
    if (window.confirm('Do you want to delete selected products,\n' + prdsString)) {
      deletetMultipleProducts(productsToDelete)
        .then(res => {
          if (res.status == 200) {
            alert('Products deleted. Click ok to refresh.')
            this.props.history.push('/products/physical/product-list');
            window.location.reload();
          } else {
            alert('Error while deleting products. Please Try after some time')
          }
        })
        .catch((err) => {
          alert('Error while deleting products. Please try after some time')
          window.location.reload();
        })
    }
  }

  render() {
    const styles = {
      "margin-top": "15px"
    };
    const { products, symbol } = this.props;
    return (
      <Fragment>
        <Breadcrumb title="Product List" parent="Shop" />
        <div className="container-fluid">

          <div className="card-header mb-3" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
            <div>
              <h5 className="mb-3">Search Products with SKU</h5>
              <div id="search-overlay" className="search-overlay">
                <div>
                  <div className="overlay-content">
                    <div className="row" >
                      <div className="col-xl-12">
                        <form>
                          <div className="form-group" style={{ maxWidth: '650px' }}>
                            <input type="text" className="form-control" id="search" placeholder="Search product..." />
                          </div>
                          <div id="match-list"> </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button type="submit" className="btn btn-primary mt-3" onClick={(e) => {
                this.deleteSelectedProducts()
              }}>
                Delete selected products
              </button>
            </div>
          </div>

          <div className="row products-admin ratio_asos">
            {
              this.state.searchPrds ?
                this.state.searchPrds.map((products, idx) => {
                  var isChecked = false;
                  for (var i = 0; i < this.state.selectedPrdsToDelete.length; i++) {
                    if (this.state.selectedPrdsToDelete[i].id === products.id) {
                      isChecked = true;
                      break;
                    }
                  }
                  return (
                    <div key={idx} className="col-xl-3 col-sm-6">
                      <div className="card" style={isChecked ? { border: '2px solid red' } : { border: 'none' }}>
                        <div className="products-admin">
                          <div className="card-body product-box">
                            <div className="img-wrapper">
                              <div className="lable-block">
                                {
                                  products === "on sale" ? (
                                    <span className="lable4">
                                      {products.discount}
                                    </span>
                                  ) : (
                                    ""
                                  )
                                }
                              </div>
                              <div className="front">
                                <div className="bg-size">
                                  {
                                    products.image[0] ?
                                      <img
                                        className="img-fluid blur-up bg-img lazyloaded"
                                        src={`${process.env.REACT_APP_BASE_URL}` + products.image[0]}
                                      /> : ''
                                  }
                                </div>
                                <div className="product-hover">
                                  <ul>
                                    <li>
                                      <Link to={`${process.env.PUBLIC_URL}/products/physical/product-detail/${products.id}`}>
                                        <button className="btn" type="button">
                                          <Eye className="editBtn" />
                                        </button>
                                      </Link>
                                    </li>
                                    <li>
                                      <button className="btn" type="button" onClick={(e) => this.linkToUpdatePage(e, products.id)}>
                                        <Edit className="editBtn" />
                                      </button>
                                    </li>
                                    <li>
                                      <button className="btn" type="button" onClick={(e) => this.deleteItem(e, products.id, products.image)}>
                                        <Trash2 className="deleteBtn" />
                                      </button>
                                    </li>
                                    <li>
                                      <button className="btn" type="button" onClick={(e) => {
                                        if (isChecked) {
                                          this.setState({
                                            selectedPrdsToDelete: this.state.selectedPrdsToDelete.filter((_, i) => {
                                              if (_.id !== products.id) {
                                                return products;
                                              }
                                            })
                                          })
                                        } else {
                                          this.setState({
                                            selectedPrdsToDelete: [...this.state.selectedPrdsToDelete, products]
                                          })
                                        }
                                      }}>
                                        <CheckCircle className="deleteBtn" />
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="product-detail" style={{ marginTop: 15 }}>
                              <h6>SKU: <b>{products.sku}</b></h6>
                              <h6>{products.name}</h6>
                              <h4>{symbol}{products.price}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }) : ""
            }
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.data.products,
  symbol: state.data.symbol,
})

export default connect(mapStateToProps)(ProductList);