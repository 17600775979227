import React, { Component, Fragment } from 'react';
import axios from "axios";

import Tabset_user from '../users/tabset-user';
import Breadcrumb from '../common/breadcrumb';
import { makeResellers } from './../ProductFunctions';

export class AddReseller extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //startDate: new Date(),
        };
        //this.handleChange = this.handleChange.bind(this);
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit = (e) => {

        let spinner = document.getElementById("spinneranimid");
        spinner.style.display = "block";

        e.preventDefault();

        const user = {
            email: this.state.email,
        };

        makeResellers(user)
            .then(res => {
                if (res.status === 200) {
                    alert(`${user.email} added as Reseller`)
                    this.props.history.push('/resellers/list_resellers');
                    window.location.reload();
                } else if (res.status === 405) {
                    alert(`User account does not exist!!`)
                    spinner.style.display = "none";
                }
            })
            .catch((err) => {
                alert("Something went wrong")
                spinner.style.display = "none";
            });

        //     axios
        //         .post("/users/makereseller", {
        //             email: user.email,
        //         })
        //         .then((res) => {
        //             if (res.status === 200) {
        //                 alert(`${user.email} added as Reseller`)
        //                 this.props.history.push('/resellers/list_resellers');
        //                 window.location.reload();
        //             } else if (res.status === 405) {
        //                 alert(`User account does not exist!!`)
        //             }
        //             return res
        //         })
        //         .catch((err) => {
        //             alert("Something went wrong")
        //             spinner.style.display = "none";
        //             return err.response;
        //         });
    }

    render() {
        return (
            <Fragment>
                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>
                <Breadcrumb title="Create Vendor" parent="Vendors" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5> Add Reseller</h5>
                                </div>
                                <div className="card-body">
                                    <form
                                        className="needs-validation user-add"
                                        noValidate=""
                                        onSubmit={this.onSubmit}
                                    >

                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Email</label>
                                            <input className="form-control col-xl-8 col-md-7" required
                                                name="email"
                                                type="email"
                                                placeholder="Email"
                                                id="exampleInputEmail1"
                                                value={this.state.email}
                                                onChange={this.onChange}
                                            />
                                        </div>

                                        <div className="offset-xl-3 offset-sm-4">
                                            <button
                                                type="submit"
                                                className="btn btn-primary">
                                                Make Reseller
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </Fragment >
        )
    }
}

export default AddReseller
