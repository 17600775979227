import { Home, Box, DollarSign, Tag, User, UserPlus, Users, Codepen, MessageCircle, GitPullRequest } from 'react-feather';

export const MENUITEMS = [
    {
        path: '/dashboard', title: 'Dashboard', icon: Home, type: 'link', badgeType: 'primary', active: false
    },
    {
        title: 'Shop', icon: Box, type: 'sub', active: false, children: [
            { path: '/category', title: 'Manage Sub-Category', type: 'link' },
            { path: '/manage-colors', title: 'Manage Colors', type: 'link' },
            { path: '/manage-price-tags', title: 'Manage Price Tags', type: 'link' },
            { path: '/add-product', title: 'Add Product', type: 'link' },
            { path: '/products/physical/product-list', title: 'Product List', type: 'link' },
            { path: '/products/variants', title: 'Product Variants', type: 'link' },
        ]
    },
    {
        title: 'Sales', icon: DollarSign, type: 'sub', active: false, children: [
            { path: '/orders', title: 'Orders', type: 'link' },
            // { path: '/transactions', title: 'Transactions', type: 'link' }
            // { path: '/sales/transactions', title: 'Transactions', type: 'link' },
        ]
    },
    // {
    //     title: 'Resellers', icon: UserPlus, type: 'sub', active: false, children: [
    //         { path: '/resellers/add-resellers', title: 'Add Reseller', type: 'link' },
    //         { path: '/resellers/list_resellers', title: 'Reseller List', type: 'link' },
    //         { path: '/coupon-codes', title: 'Coupon Codes', type: 'link' },
    //     ]
    // },
    {
        title: 'Utilities', icon: Codepen, type: 'sub', active: false, children: [
            { path: '/utilities/show-offgallery', title: 'Show-OffGallery', type: 'link' },
            { path: '/utilities/testimonials', title: 'Testimonials', type: 'link' },
            // { path: '/utilities/add-links', title: 'Add Links', type: 'link' },
            { path: '/utilities/daily-deals', title: 'Daily Deals', type: 'link' },
            { path: '/utilities/landing-page-banner', title: 'Main Page Banner', type: 'link' }
        ]
    },
    {
        title: 'Users/Customer List', icon: Users, type: 'sub', active: false, children: [
            { path: '/user-list', title: 'User List', type: 'link' }
        ]
    },
    {
        title: 'Requests', icon: GitPullRequest, type: 'sub', active: false, children: [
            // { path: '/requests/withdrawl', title: 'Withdrawl', type: 'link' },
            { path: '/requests/returnexchange', title: 'Return & Exchange', type: 'link' },
        ]
    },
    // {
    //     title: 'Coupons', icon: Tag, type: 'sub', active: false, children: [
    //         { path: '/coupons/list-coupons', title: 'Coupon List', type: 'link' },
    //         { path: '/coupons/create-coupons', title: 'Create Coupon', type: 'link' },
    //     ]
    // },
    {
        path: '/smsgateway', title: 'SMS', icon: MessageCircle, type: 'link', badgeType: 'primary', active: false

    },
    {
        path: '/settings/profile', title: 'Profile', icon: User, type: 'link', badgeType: 'primary', active: false
    },

]
