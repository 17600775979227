import axios from 'axios';

export const fetchCustomers = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/fetchcustomers`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const updateCustomer = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/updatecustomer`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchRechargeRequests = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/fetchrechargerequests`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const approveRechargeRequest = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/approverechargerequest`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchWithdrawlRequests = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/fetchwithdrawlrequests`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const approveWithdrawlRequest = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/approvewithdrawlrequest`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const sendLinks = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/sendlinks`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};