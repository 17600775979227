import React, { Component, Fragment } from 'react'
import Breadcrumb from '../../common/breadcrumb';
import Modal from 'react-responsive-modal';
import 'react-toastify/dist/ReactToastify.css';
import { fetchCategoryData, addSubCategory, editCategory, deleteSubCategory } from "../physical/product-functions/product-functions";
// import data from '../../../assets/data/category';
// import Datatable from '../../common/datatable';


export class Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            categoryData: [],
            category: [],
            subcategory: [],
            cat: '',
            subcat: '',
            subtype: [],
            subtest: [],
            indexofSubcategory: '',
            selectedView: '-Select Category-',
            addorupdate: 'Add Subcategory',
            oldCategory: '',
            // updatedCategory: '',
            addInCategory: '',
            newsubcategory: '',
            oldSubCategory: '',
            updatedSubCategory: ''


        };
    }

    componentDidMount() {
        var checkusertoken = localStorage.getItem('usertokenSatyamAdmin');

        if (checkusertoken == null) {
            this.props.history.push(`/`)
        } else {
            fetchCategoryData()
                .then(res => {
                    if (res.status == 200) {
                        var catArr = []
                        var subCatArr = []
                        for (var i = 0; i < res.data.length; i++) {
                            catArr.push(res.data[i].category)
                            subCatArr.push(res.data[i].subcategory)
                            const getSubcategory = res.data.find(subcat => subcat.category === this.state.category);
                        }

                        let uniqueCats = [...new Set(catArr)];

                        this.setState({
                            category: uniqueCats,
                            categoryData: res.data
                        })

                        var subcategoriestest = [
                            { value: ["-Select Category-"], name: '-Select Category-' }
                        ]

                        for (let i = 0, j = 0; i < this.state.subcategory.length, j < this.state.category.length; i++, j++) {
                            let initvalue = ["-Select Sub-Category-"]

                            let sub = this.state.subcategory[i]

                            const finalvalue = initvalue.concat(sub)

                            let subcatobject = {
                                value: finalvalue, name: this.state.category[j]
                            }
                            subcategoriestest.push(subcatobject)
                        }

                        this.setState({
                            subtest: subcategoriestest
                        })


                    } else if (res.status === 401) {
                        this.setState({ categoryData: [] })
                    } else {
                        alert('Error Occured.. Please Try after some time')
                    }
                })
                .catch((err) => {
                    alert(err)
                })
        }
    }

    addSubCategory = (e) => {
        e.preventDefault();

        if (window.confirm('Add the Sub-Category ?')) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const subcategory = {
                category: this.state.addInCategory,
                subcategory: this.state.newsubcategory
            }

            addSubCategory(subcategory)
                .then(res => {
                    if (res.status == 200) {
                        alert('Sub-Category Added ..! Please refresh the page after fully loaded..')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.status == 401 && res.data.error == 'SubCategory Already Exists') {
                        alert('This Sub-Category already exists..!')
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured While Adding the Sub-Category.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured While Adding the category.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    updateSubCategory = (e) => {
        e.preventDefault();

        if (window.confirm('Update the Sub-Category ?')) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const subcatUpdateData = {
                oldSubCategory: this.state.oldSubCategory,
                updatedSubCategory: this.state.updatedSubCategory
            }

            editCategory(subcatUpdateData)
                .then(res => {
                    if (res.status == 200) {
                        alert('Sub-Category Updated ..! Please refresh the page after fully loaded..')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.status == 405 && res.data.error == 'Subcategory Does not exists.') {
                        alert('Old Sub-Category does not exists..!')
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured While Updating the category.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {

                    alert('Error Occured While Updating the category.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    deleteSubCategory = (e, id) => {
        e.preventDefault();

        if (window.confirm('Delete the Category ?')) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const data = {
                id: id,
            }

            deleteSubCategory(data)
                .then(res => {
                    if (res.status == 200) {
                        alert('Sub-Category Deleted ..! Please refresh the page after fully loaded..')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured While Deleting the Sub-Category.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured While Deleting the Sub-Category.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    subcatfunction = () => {
        this.setState({ open: true });
    };

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }


    getSubcategory = () => {
        try {
            let subcategories = this.state.subtest
            // const subcategories = [
            //     { value: ["-Select Category-"], name: '-Select Category-' },
            //     { value: ["-Select Sub-Category-", this.state.dataString1[0], this.state.dataString1[1], this.state.dataString1[2], this.state.dataString1[3], this.state.dataString1[4]], name: JSON.stringify(this.state.category[0]) },
            //     { value: ["-Select Sub-Category-", this.state.dataString2[0], this.state.dataString2[1], this.state.dataString2[2], this.state.dataString2[3]], name: JSON.stringify(this.state.category[1]) },
            //     { value: ["-Select Sub-Category-", this.state.dataString3[0], this.state.dataString3[1], this.state.dataString3[2], this.state.dataString3[3]], name: JSON.stringify(this.state.category[2]) },
            //     { value: ["-Select Sub-Category-", this.state.dataString4[0], this.state.dataString4[1], this.state.dataString4[2], this.state.dataString4[3], this.state.dataString4[4]], name: JSON.stringify(this.state.category[3]) },
            //     { value: ["-Select Sub-Category-", this.state.dataString5[0], this.state.dataString5[1], this.state.dataString5[2]], name: JSON.stringify(this.state.category[4]) }
            // ];

            const view = subcategories.filter(({ name }) => name === this.state.selectedView)[0]

            return (
                <div>
                    <select
                        required
                        className="form-control mb-0"
                        name="subcat"
                        id="subcat"
                        value={this.state.subcat}
                        onChange={this.onChange}
                    >
                        {view.value.map(m => <option value={m}>{m}</option>)}
                    </select>
                </div>
            )

        }
        catch (err) {
        }

    }

    // FindIndex function
    findIndex = (data, subcategory) => {
        const el = data.find(el => el.subcategory === subcategory); // Possibly returns `undefined`
        return el && data.indexOf(el); // so check result is truthy and extract `id`
    };

    render() {
        const { open, categoryData } = this.state;
        const { products } = this.props;
        const { selectedView } = this.state

        const getSubcategory = categoryData.filter(item => (item.subcategory));

        var indexofcategory
        var indexofSubcategory
        var subcategories = []
        var subtypes = []
        var s = []

        subcategories = this.state.subtest
        subtypes = this.state.subtype
        s = subtypes[0]


        const findIndex = (data, subcategory) => {

            const el = data[0].find(el => el.subcategory === subcategory); // Possibly returns `undefined`
            return el && data[0].indexOf(el); // so check result is truthy and extract `id`
        };

        if (this.state.category.length > 0) {
            var c = this.state.category
            var ce = this.state.cat

            // var ceslice = ce.slice(1, -1);
            indexofcategory = c.indexOf(ce);

        } else {
        }

        if (this.state.subtype.length > 0) {
            indexofSubcategory = findIndex(this.state.subtype, this.state.subcat);
        } else {
        }


        return (
            <Fragment>
                <Breadcrumb title="Category" parent="Shop" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Add or Update Category and Subcategory</h5>
                                </div>
                                <div className="card-header">
                                    <select className="form-control" id="addorupdate" name="addorupdate" value={this.state.addorupdate} onChange={(e) => this.setState({ addorupdate: e.target.value })} style={{ maxWidth: '250px' }}>
                                        {/* <option value="Update Category">Update Category</option> */}
                                        <option value="Add Subcategory">Add Sub-Category </option>
                                        <option value="Update Subcategory">Update Sub-Category</option>

                                    </select>

                                    <hr />

                                    {/* <div style={{ display: this.state.addorupdate == 'Update Category' ? '' : 'none' }}>
                                        <h5>Update Category</h5>
                                        <form onSubmit={this.updateCategory}>
                                            <label className='mt-3'>Old Category:</label>
                                            <input style={{ maxWidth: '350px' }} className="form-control" id="oldCategory" readOnly value={this.state.cat} name="oldCategory" onChange={(e) => this.setState({ oldCategory: e.target.value })} required />
                                            <label className='mt-3'>New Category:</label>
                                            <input style={{ maxWidth: '350px' }} className="form-control" id="updatedCategory" name="updatedCategory" onChange={(e) => this.setState({ updatedCategory: e.target.value })} required />
                                            <button type="submit" className="btn btn-primary mt-3">
                                                Update
                                            </button>
                                        </form>
                                    </div> */}

                                    <div style={{ display: this.state.addorupdate == 'Add Subcategory' ? '' : 'none' }}>
                                        <h5>Add Sub-Category</h5>
                                        <form onSubmit={this.addSubCategory}>
                                            <label className='mt-3'>Add in Category:</label>
                                            {/* <input style={{ maxWidth: '350px' }} className="form-control" id="addInCategory" value={this.state.cat} name="addInCategory" onChange={(e) => this.setState({ addInCategory: e.target.value })} required /> */}
                                            <select
                                                required
                                                className="form-control mb-0"
                                                name="addInCategory"
                                                id="addInCategory"
                                                value={this.state.addInCategory}
                                                onChange={(e) => this.setState({ selectedView: e.target.value, addInCategory: e.target.value })}
                                            >
                                                {this.state.subtest.map(({ name, key }) => {
                                                    return <option value={name}>{name}</option>;
                                                })}
                                            </select>

                                            <label className='mt-3'>New Sub-Category:</label>
                                            <input style={{ maxWidth: '350px' }} className="form-control" id="newsubcategory" name="newsubcategory" onChange={(e) => this.setState({ newsubcategory: e.target.value })} required />
                                            <button type="submit" className="btn btn-primary mt-3">
                                                Add
                                            </button>
                                        </form>
                                    </div>

                                    <div style={{ display: this.state.addorupdate == 'Update Subcategory' ? '' : 'none' }}>
                                        <h5>Update Sub-Category</h5>
                                        <form onSubmit={this.updateSubCategory}>
                                            <label className='mt-3'>Old Sub-Category:</label>
                                            <input style={{ maxWidth: '350px' }} className="form-control" id="oldSubCategory" name="oldSubCategory" onChange={(e) => this.setState({ oldSubCategory: e.target.value })} required />
                                            <label className='mt-3'>New Sub-Category:</label>
                                            <input style={{ maxWidth: '350px' }} className="form-control" id="updatedSubCategory" name="updatedSubCategory" onChange={(e) => this.setState({ updatedSubCategory: e.target.value })} required />
                                            <button type="submit" className="btn btn-primary mt-3">
                                                Update
                                            </button>
                                        </form>
                                    </div>

                                    <div className="card-body order-datatable lists-custom">

                                        {this.state.categoryData.length > 0 ?
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Sr. No.</th>
                                                        <th>Category Name</th>
                                                        <th>Subcategory Name</th>
                                                        <th>Delete SubCategory</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.categoryData.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.category}</td>
                                                            <td>{item.subcategory}</td>
                                                            <td>
                                                                <a href='#' onClick={(e) => this.deleteSubCategory(e, item.id)}><i className="fa fa-times" /></a>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>

                                            :
                                            <div>
                                                <p>No Category has been Added Yet.</p>
                                            </div>
                                        }
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default Category