import axios from "axios";

//fetch single order
export const getClient = async (client) => {
    return axios
        .post("https://api.reecraft.com/smsgatewayrequest/getclient", client)
        .then((res) => {
            return res.data;
        })
        .catch(err => {
            return err;
        });
};
