import React, { Component, Fragment } from 'react'

import Breadcrumb from '../common/breadcrumb';

import { fetchTransactions } from './../../api/order';

export class Transactions extends Component {

    constructor(props) {
        super(props)

        this.state = {
            txns: [],
            toSearch: '',
            pages: 0,
            pagesArray: [],
            currentPage: 1,
            offset: 0,
            limit: 50
        }
    }

    callFetchTransactions = (e) => {
        let spinner = document.getElementById("spinneranimid");
        spinner.style.display = "block";
        var data = {
            offset: this.state.offset,
            limit: this.state.limit,
            searchString: this.state.toSearch.toLowerCase()
        }
        fetchTransactions(data)
            .then(res => {
                if (res.status == 200) {
                    this.setState({ txns: res.data.txns, pages: res.data.count % this.state.limit == 0 ? res.data.count / this.state.limit : parseInt(res.data.count / this.state.limit) + 1 }, () => {
                        var pagesArray = [];
                        for (var i = 0; i < this.state.pages; i++) {
                            pagesArray.push(i + 1)
                        }
                        this.setState({ pagesArray: pagesArray })
                        spinner.style.display = "none";
                    })
                } else {
                    alert('Error Occured.. Please Try after some time')
                    spinner.style.display = "none";
                }
            })
            .catch((err) => {
                alert('Error Occured.. Please Try after some time')
                spinner.style.display = "none";
            })
    }

    componentDidMount() {
        var checkusertoken = localStorage.getItem('usertokenSatyamAdmin');
        if (checkusertoken == null) {
            this.props.history.push(`/`)
        } else {
            this.callFetchTransactions();
        }

    }

    searchParam = (e) => {
        e.preventDefault();
        this.setState({ currentPage: 1, offset: 0, limit: 50 }, () => {
            this.callFetchTransactions();
        })
    }

    resetSearch = e => {
        e.preventDefault();
        window.location.reload();
    }

    changePage = e => {
        var offset = this.state.limit * (e.target.value - 1)
        this.setState({ currentPage: e.target.value, offset: offset }, () => {
            this.callFetchTransactions();
        })
    }

    render() {
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

        return (
            <Fragment>
                <Breadcrumb title="Transactions" parent="Sales" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">

                                    <h5 className="mb-2">Search Transaction By</h5>
                                    <p>( transaction id, order id )</p>
                                    <form onSubmit={this.searchParam}>
                                        <input style={{ maxWidth: '300px' }} className="form-control" id="searchParam" name="searchParam" onChange={(e) => this.setState({ toSearch: e.target.value })} required />

                                        <button type="submit" className="btn btn-primary mt-3">
                                            Search
                                        </button>

                                        <button type="btn" className="btn btn-primary mt-3 ml-3" onClick={this.resetSearch}>
                                            Reset
                                        </button>
                                    </form>

                                </div>
                                <div className="card-body order-datatable lists-custom">

                                    {this.state.txns.length > 0 ?
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>User ID</th>
                                                    <th>Name</th>
                                                    <th>Txn. ID</th>
                                                    <th>Date</th>
                                                    {/* <th>From</th>
                                                    <th>To</th> */}
                                                    <th>Amount</th>
                                                    <th>Type</th>
                                                    {/* <th>Order No.</th> */}
                                                    <th>Source</th>
                                                    <th>Reseller Wallet</th>
                                                    <th>1k Wallet</th>
                                                    <th>2k Wallet</th>
                                                    <th>COD Wallet</th>
                                                    <th>Payment Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.txns.map((item, index) => {
                                                    return (
                                                        <tr key={index}>

                                                            <td>{item.id}</td>
                                                            <td>{item.user_id}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.txn_id}</td>
                                                            <td>{days[new Date(item.date).getDay()] + ', ' + monthNames[new Date(item.date).getMonth()] + ' ' + new Date(item.date).getDate() + ', ' + new Date(item.date).getFullYear()}</td>
                                                            {/* <td>{item.from_ownref}</td>
                                                            <td>{item.to_ownref}</td> */}
                                                            <td>
                                                                <strong>
                                                                    ₹&nbsp;{item.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                </strong>
                                                            </td>
                                                            <td>{item.type}</td>
                                                            {/* <td>{item.order_id.replace("PO", "").replace("W", "").replace("H", "").replace("E", "").replace("R", "")}</td> */}
                                                            <td>{item.source}</td>
                                                            <td>₹&nbsp;{item.resellerdeposit}</td>
                                                            <td>₹&nbsp;{item.onekwallet}</td>
                                                            <td>₹&nbsp;{item.twokwallet}</td>
                                                            <td>₹&nbsp;{item.codwallet}</td>
                                                            <td style={{ color: item.payment_status == 'Completed' ? 'green' : 'red' }}><strong>{item.payment_status}</strong></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                        :
                                        <div>
                                            <p>No Transaction has been made yet.</p>
                                        </div>
                                    }
                                    <hr />
                                    <select className="form-control mt-3 mb-3" id="pages" name="pages" value={this.state.currentPage} onChange={this.changePage} style={{ maxWidth: '250px' }}>
                                        {this.state.pagesArray.map((item, index) =>
                                            <option key={index} value={item}>Page {item}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )

    }
}

export default Transactions;
