/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from "react";

import { connect } from 'react-redux';
import { Link } from "react-router-dom";
// import CKEditors from "react-ckeditor-component";
// import { AvField, AvForm } from "availity-reactstrap-validation";
import axios from "axios";

import { Edit, Trash2 } from "react-feather";
import Swiper from "react-id-swiper";
import Breadcrumb from "../common/breadcrumb";

import one from "../../assets/images/pro3/1.jpg";
import user from "../../assets/images/user.png";
import { fetchNewTestimonial } from "../LandingFunctions";
import { createNewTestimonial } from "./UtilityFunctions";
import { deleteNewTestimonial } from "./UtilityFunctions";

export class NewTestimonial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newId: '',
      id: '',
      LandingReviewsData: [],
      image: [
        {}
      ],
      imgfiles: [
        {}
      ],
      filenames: [
        {}
      ],
      reader: [one],
      dummyimgs: [
        { img: user }
      ]
    };

    const settings = {
      slidesPerView: 1,
      loop: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false
      }
    };
  };

  componentDidMount() {
    var checkusertoken = localStorage.getItem('usertokenSatyamAdmin');
    if (checkusertoken == null) {
      this.props.history.push(`/dashboard`)
    } else {
      fetchNewTestimonial()
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              LandingReviewsData: res.data
            })

            var products = this.state.LandingReviewsData.id
            var uniqueId = [];

            for (let i = 0; i < Object.keys(products).length; i++) {
              uniqueId.push(products[i].id);
            }

            var max = Math.max.apply(Math, uniqueId);

            const newId = max + 1;

            this.setState({
              newId: newId
            })


          } else {
          }
        })
        .catch((err) => {
        })
    }
  }

  updateContent(newContent) {
    this.setState({
      content: newContent
    })
  }

  onBlur(evt) {

  }

  afterPaste(evt) {

  }

  ontxtChange = (evt) => {
    var newContent = evt.editor.getData();
    this.setState({
      content: newContent
    })
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  _handleImgChange = (e, i) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    const { dummyimgs } = this.state;
    const { filenames } = this.state;
    const { imgfiles } = this.state;
    const path = '/assets/img/testimonial/';

    reader.onloadend = () => {
      dummyimgs[i].img = reader.result;
      filenames[i] = path + file.name;

      imgfiles[i] = file
      this.setState({
        file: file,
        filenames,
        dummyimgs,
        imgfiles,
        reader: reader.result,
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  handleValidSubmit = (e) => {
    e.preventDefault();

    let spinner = document.getElementById("spinneranimid");

    if (window.confirm('Add Image?')) {
      spinner.style.display = "block";

      var array1 = this.state.filenames
      var imageFiles = array1.filter(value => Object.keys(value).length !== 0);

      // var array2 = this.state.variants
      // var imgvariants = array2.filter(value => Object.keys(value).length !== 0);

      const newProd = {
        title: 'Enjoy This Offer Today 1',
        subtitle: 'New Collection <br>Sale 40%',
        image: this.state.filenames[0],
        url: '/shop-grid'
      };

      //Image Upload
      let files = this.state.imgfiles;

      var formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i])
      }

      formData.append('newProd', JSON.stringify(newProd))

      // axios.post('/users/upload-showoffgallery-image', formData)
      //   .then((res) => {
      // Post Jason
      createNewTestimonial(formData)
        .then((res) => {
          if (res.status === 200) {
            window.location.reload();
          }
          else {
            spinner.style.display = "none";
            alert("Something went wrong");
          }
        })
        .catch((error) => {
          spinner.style.display = "none";
          alert("Something went wrong");
        });
      // })
      // .catch((error) => {
      //   spinner.style.display = "none";
      //   alert("Something went wrong");
      // });
    };
  };

  ResetForm() {
    // window.confirm('Reset Form?');
    if (window.confirm('Reset Form?')) {
      window.location.reload();
    }
  }

  deleteItem = async (e, id, imagepath) => {
    const Itemid = {
      id: id,
      path: imagepath
    }

    if (window.confirm('Are you sure?')) {
      deleteNewTestimonial(Itemid)
        .then((res) => {
          if (res) {
            window.location.reload();
          } else {
            window.alert('Something went wrong!')
          }
        })
        .catch(err => {
        });
    }
  };
  render() {
    const { products } = this.props;
    const { selectedView } = this.state

    return (
      <Fragment>
        <div id="spinneranimid" className="overlay-spinner">
          <div className="cssload-whirlpool" />
        </div>
        <Breadcrumb title="Add Testimonials" parent="Physical" />
        {/* <input type="select" onChange={this.onDropdownSelected} label="Multiple Select" multiple>
          {this.createSelectItems()}
        </input> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Add Testimonials Images</h5>
                </div>
                <div className="card-body">
                  <div className="row product-adding">
                    <div className="col-xl-5">
                      <div className="add-product">
                        <div className="row">
                          <div className="col-xl-9 xl-50 col-sm-6 col-9">
                            <img
                              src={this.state.reader}
                              alt=""
                              className="img-fluid image_zoom_1 blur-up lazyloaded"
                            />
                          </div>
                          <div className="col-xl-3 xl-50 col-sm-6 col-3">
                            <ul className="file-upload-product">
                              {this.state.dummyimgs.map((res, i) => {
                                return (
                                  <li key={i}>
                                    <div className="box-input-file">
                                      <input
                                        required
                                        className="upload"
                                        type="file"
                                        multiple
                                        onChange={(e) =>
                                          this._handleImgChange(e, i)
                                        }
                                        onSubmit={this.handleValidSubmit}
                                      />
                                      <img
                                        src={res.img}
                                        style={{ width: 50, height: 50 }}
                                      />
                                      <a
                                        id="result1"
                                        onClick={(e) =>
                                          this._handleSubmit(e.target.id)
                                        }
                                      ></a>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7" style={{ marginTop: 30 }}>
                      <form
                        // className="needs-validation add-product-form"
                        onSubmit={this.handleValidSubmit}
                      //onInvalidSubmit={this.handleInvalidSubmit}
                      >

                        <div className="offset-xl-3 offset-sm-4">
                          <button type="submit" className="btn btn-primary">
                            Add Images
                          </button>
                          <button type="button" className="btn btn-light" onClick={this.ResetForm}>
                            Discard
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-header" style={{ backgroundColor: '#fff', borderBottom: '0', marginBottom: '10px' }}>
            <h5>Testimonial Images</h5>
          </div>

          <div className="row products-admin ratio_asos">
            {this.state.LandingReviewsData &&
              this.state.LandingReviewsData.map((products, index) => {
                return (
                  <div className="col-xl-3 col-sm-6">
                    <div className="card">
                      <div className="products-admin">
                        <div className="card-body product-box">
                          <div className="img-wrapper">
                            <div className="lable-block">

                            </div>
                            <div className="front">
                              <a className="bg-size">
                                <img
                                  className="img-fluid blur-up bg-img lazyloaded"
                                  src={`${process.env.REACT_APP_BASE_URL}` + products.image}
                                />
                              </a>
                              <div className="product-hover">
                                <ul>
                                  {/* <li>
                                    <Link to={`${process.env.PUBLIC_URL}/products/physical/update-product/${products.id}`}>
                                      <button className="btn" type="button">
                                        <Edit className="editBtn" />
                                      </button>
                                    </Link>
                                  </li> */}
                                  <li>
                                    <Link >
                                      <button className="btn" type="button" onClick={(e) => this.deleteItem(e, products.id, products.image)}>
                                        <Trash2 className="deleteBtn" />
                                      </button>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="product-detail" style={{ marginTop: 15 }}>
                            <a>
                              {" "}
                              <h6>Id: <b>{index + 1}</b></h6>
                            </a>

                            {/* <a>
                              {" "}
                              <h6>{products.name}</h6>
                            </a> */}


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

          {/* <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Show-Off Gallery</h5>
                </div>
                <div className="card-body">

                  <div className="col-md-4 col-lg-6 order-2 order-lg-1">
                    <div className="funfact-image" style={{ borderRadius: "15px" }}>

                      <Swiper >
                        {this.state.LandingReviewsData &&
                          this.state.LandingReviewsData.map((single, key) => {
                            return (
                              <LandingReviewsSingle
                                data={single}
                                key={key}
                                sliderClass="swiper-slide"
                              />
                            );
                          })}

                        <img
                          alt=""
                          className="img-fluid2"
                        />
                      </Swiper>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div> */}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.data.products,
  symbol: state.data.symbol,
})

export default connect(mapStateToProps)(NewTestimonial);
