import axios from "axios";

export const register = (newUser) => {
  return axios
    .post("/users/registeradmin", {
      email: newUser.email,
      username: newUser.username,
      password: newUser.password,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};


export const login = (user) => {
  return axios
    .post("users/adminlogin", {
      username: user.username,
      password: user.password,
    })
    .then((res) => {
      localStorage.setItem("usertokenSatyamAdmin", res.data);
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};
