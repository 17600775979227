import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Breadcrumb from "../../common/breadcrumb";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from "react-responsive-modal";
import StarRatingComponent from "react-star-rating-component";

// image import
// import two from "../../../assets/images/pro3/2.jpg";
// import twentySeven from "../../../assets/images/pro3/27.jpg";
// import one from "../../../assets/images/pro3/1.jpg";
// import size_chart from "../../../assets/images/size-chart.jpg";
import { deleteProduct } from "../../../api/product";
import { fetchPrdFilterColor } from "./product-functions/product-functions";

export class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: [],
      nav1: null,
      nav2: null,
      prdFilterColors: []
    };
  }

  deleteItem = async () => {
    const productData = {
      Itemid: this.props.item.id,
      image: this.props.item.image
    };

    if (window.confirm('Delete this Item?')) {
      deleteProduct(productData)
        .then(res => {
          if (res.status == 200) {
            alert('Product Item Deleted. Click ok to refresh.')
            this.props.history.push('/products/physical/product-list');
            window.location.reload();
          } else {
            alert('Error while deleting. Please Try after some time')
          }
        })
        .catch((err) => {
          alert('Error while deleting product. Please try after some time')
          window.location.reload();
        })
    }
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onStarClick(nextValue, prevValue, name) {
    this.setState({ rating: nextValue });
  }

  onStarHover(nextValue, prevValue, name) {
    this.setState({ rating: nextValue });
  }
  /* on icon hover handler */
  onStarHoverOut(nextValue, prevValue, name) {
    this.setState({ rating: nextValue });
  }

  IncrementItem = () => {
    this.setState((prevState) => {
      if (prevState.quantity < 9) {
        return {
          quantity: prevState.quantity + 1,
        };
      } else {
        return null;
      }
    });
  };

  DecreaseItem = () => {
    this.setState((prevState) => {
      if (prevState.quantity > 0) {
        return {
          quantity: prevState.quantity - 1,
        };
      } else {
        return null;
      }
    });
  };
  handleChange
    = (event) => {
      this.setState({ quantity: event.target.value });
    };

  componentDidMount = async () => {
    await fetchPrdFilterColor({ id: this.props.item.id })
      .then((response) => {
        if (response.data.status = 200 && response.data.data) {
          this.setState({
            prdFilterColors: response.data.data
          })
        }
      })
      .catch(error => {
        console.log(error);
      })
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const { item, symbol } = this.props;
    const { open } = this.state;
    const { rating } = this.state;
    return (
      <Fragment>
        <Breadcrumb title="Product Detail" parent="Physical" />
        <div className="container-fluid">
          <div className="card">
            <div className="row product-page-main card-body">
              <div className="col-xl-4">
                <Slider
                  asNavFor={this.state.nav2}
                  ref={(slider) => (this.slider1 = slider)}
                  className="product-slider"
                >
                  <div className="item">
                    <img className="img-fluid" src={`${process.env.REACT_APP_BASE_URL}/${item.image[0]}`} alt="" />
                  </div>
                  <div className="item">
                    <img className="img-fluid" src={`${process.env.REACT_APP_BASE_URL}/${item.image[1]}`} alt="" />
                  </div>
                  <div className="item">
                    <img className="img-fluid" src={`${process.env.REACT_APP_BASE_URL}/${item.image[2]}`} alt="" />
                  </div>
                  <div className="item">
                    <img className="img-fluid" src={`${process.env.REACT_APP_BASE_URL}/${item.image[3]}`} alt="" />
                  </div>
                  <div className="item">
                    <img className="img-fluid" src={`${process.env.REACT_APP_BASE_URL}/${item.image[4]}`} alt="" />
                  </div>
                  <div className="item">
                    <img className="img-fluid" src={`${process.env.REACT_APP_BASE_URL}/${item.image[5]}`} alt="" />
                  </div>
                </Slider>

                <Slider
                  asNavFor={this.state.nav1}
                  ref={(slider) => (this.slider2 = slider)}
                  slidesToShow={4}
                  swipeToSlide={true}
                  focusOnSelect={true}
                  className="small-slick"
                >
                  <div className="item">
                    <img className="img-fluid" src={`${process.env.REACT_APP_BASE_URL}/${item.image[0]}`} alt="" />
                  </div>
                  <div className="item">
                    <img className="img-fluid" src={`${process.env.REACT_APP_BASE_URL}/${item.image[1]}`} alt="" />
                  </div>
                  <div className="item">
                    <img className="img-fluid" src={`${process.env.REACT_APP_BASE_URL}/${item.image[2]}`} alt="" />
                  </div>
                  <div className="item">
                    <img className="img-fluid" src={`${process.env.REACT_APP_BASE_URL}/${item.image[2]}`} alt="" />
                  </div>
                  <div className="item">
                    <img className="img-fluid" src={`${process.env.REACT_APP_BASE_URL}/${item.image[4]}`} alt="" />
                  </div>
                  <div className="item">
                    <img className="img-fluid" src={`${process.env.REACT_APP_BASE_URL}/${item.image[5]}`} alt="" />
                  </div>
                </Slider>
              </div>
              <div className="col-xl-8">
                <div className="product-page-details product-right mb-0">
                  <h2>{item.name}</h2>
                  <h3>{item.category}</h3>
                  <h4>({item.subcategory})</h4>
                  <div style={{ fontSize: 27, height: 31 }}>
                    <StarRatingComponent
                      name="rate1"
                      starCount={5}
                      value={item.rating}
                      onStarClick={this.onStarClick.bind(this)}
                      onStarHover={this.onStarHover.bind(this)}
                      onStarHoverOut={this.onStarHoverOut.bind(this)}
                    />
                  </div>
                  <hr />
                  <div className="product-price digits mt-2">
                    <h6 className="product-title">SKU :</h6>
                    <h6>{item.sku}</h6>
                  </div>
                  <hr />
                  <div className="product-price digits mt-2">
                    <h6 className="product-title">Sale Price:</h6>
                    <h6>
                      <b>{symbol}</b>{item.price}
                    </h6>

                    <h6>
                      <b>Reseller Price:</b> {symbol}{item.resellerPrice}
                    </h6>
                    {/* <h6>
                      <b> Reseller Discount:</b> {item.resellerdiscount}%
                    </h6> */}
                  </div>
                  <hr />
                  <div className="add-product-form">
                    <h6 className="product-title">stock:</h6>
                    <h6>
                      <b>Stock (6 Yard)</b>: {item.variation[0].size[0].stock}
                    </h6>
                    <h6>
                      <b>Stock (9 Yard)</b>: {item.variation[0].size[0].stock}
                    </h6>
                    {/* <h6>
                      <b>Stock (11 Yard)</b>: {item.variation[0].size[0].stock}
                    </h6> */}
                  </div>
                  <hr />
                  <div className="m-t-15">
                    <h6 className="product-title">Filter Colors : { }</h6>
                    <h6>
                      {
                        this.state.prdFilterColors.map((color, idx) => {
                          if (this.state.prdFilterColors.length - 1 == idx) {
                            return (color.ColorColor)
                          } else {
                            return (color.ColorColor + ', ')
                          }
                        })
                      }
                    </h6>
                    <h6 className="product-title">Material :</h6>
                    <h6>{item.material}</h6>
                    <h6 className="product-title">Blouse Piece :</h6>
                    <h6>{item.blousepiece}</h6>
                    <h6 className="product-title">Fabric Care :</h6>
                    <h6>{item.fabricCare}</h6>
                  </div>
                  <div className="m-t-15">
                    <h6 className="product-title">product Description:</h6>
                    <p>
                      {item.fulldescription}
                    </p>
                  </div>
                  <div className="m-t-15">
                    <Link to={`${process.env.PUBLIC_URL}/update-product/${item.id}`}>
                      <button className="btn btn-primary m-r-10" type="button">
                        Update Details
                      </button>
                    </Link>

                    <button className="btn btn-secondary" type="button" onClick={() => this.deleteItem()}>
                      Delete Item
                    </button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment >
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let productId = ownProps.match.params.id;
  return {
    item: state.data.products.find(el => el.id == productId),
    symbol: state.data.symbol
  }
}

export default connect(mapStateToProps)(ProductDetail);
