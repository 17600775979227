import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import Breadcrumb from '../common/breadcrumb';
import 'react-toastify/dist/ReactToastify.css';
import { addVariant, fetchVariant, deleteVariant, updateVariant, addDeal, deleteDeal, fetchDeal, updateDeal, fetchBanner, addBanner, deleteBanner } from "../../components/products/physical/product-functions/product-functions";

export class LandingPageBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bannerData: [],
            bannerToUpdate: '',
            addorupdate: 'Add Banner',
            newBanner: {},
            newTitle: '',
            newSubTitle: '',
            newUrl: ''
        };
    }

    componentDidMount() {
        var checkusertoken = localStorage.getItem('usertokenSatyamAdmin');
        if (checkusertoken == null) {
            this.props.history.push(`/`)
        } else {
            fetchBanner()
                .then(res => {
                    if (res.status == 200) {
                        var bannerData = res.data.data
                        if (bannerData[0] && bannerData[0].image) {
                            this.setState({
                                bannerData: bannerData,
                                bannerToUpdate: bannerData[0].id,
                            })
                        }
                    } else {
                        alert('Error occured.. Please try after some time')
                    }
                })
                .catch((err) => {
                    alert(err)
                })
        }
    }

    addBannerFn = (e) => {
        e.preventDefault();
        if (true) { //window.confirm('Add Banner?')
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            var file = this.state.newBanner
            const reader = new FileReader()

            reader.onloadend = (resp) => {
                console.log(resp);
                const data = {
                    title: this.state.newTitle,
                    subtitle: this.state.newSubTitle,
                    url: this.state.newUrl,
                    image: '/assets/img/landing/banners/' + this.state.newBanner.name,
                }

                var formData = new FormData();
                formData.append('files', file)
                formData.append('bannerdata', JSON.stringify(data))

                addBanner(formData)
                    .then(res => {
                        if (res.data.status == 200) {
                            window.location.reload();
                            spinner.style.display = "none";
                        } else if (res.data.status == 400) {
                            alert(res.data.msg)
                            spinner.style.display = "none";
                        } else {
                            alert('Error occured while adding banner. Please try after some time')
                            window.location.reload();
                            spinner.style.display = "none";
                        }
                    })
                    .catch((err) => {
                        alert('Error occured while adding banner. Please try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    })
            }
            if (file) {
                reader.readAsDataURL(file)
            }
        }
    }

    // updateDealFn = (e) => {
    //     e.preventDefault();
    //     if (window.confirm('Update Variant?')) {
    //         let spinner = document.getElementById("spinneranimid");
    //         spinner.style.display = "block";
    //         const data = {
    //             deal: this.state.dealToUpdate,
    //             products: this.state.selectedPrds,
    //         }
    //         updateDeal(data)
    //             .then(res => {
    //                 if (res.data.status == 200) {
    //                     window.location.reload();
    //                     spinner.style.display = "none";
    //                 } else if (res.data.status == 400) {
    //                     alert(res.data.msg)
    //                     spinner.style.display = "none";
    //                 } else {
    //                     alert('Error occured while updating deal. Please try after some time')
    //                     window.location.reload();
    //                     spinner.style.display = "none";
    //                 }
    //             })
    //             .catch((err) => {
    //                 alert('Error occured while updating deal. Please try after some time1')
    //                 window.location.reload();
    //                 spinner.style.display = "none";
    //             })
    //     }
    // }

    deleteBannerFn = (e, bannerId) => {
        e.preventDefault();
        if (window.confirm('Delete Deal?')) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            const data = { id: bannerId }
            deleteBanner(data)
                .then(res => {
                    if (res.data.status == 200) {
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.data.status == 400) {
                        alert(res.data.msg)
                        spinner.style.display = "none";
                    } else {
                        alert('Error occured while deleting banner. Please try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error occured while deleting banner. Please try after some time1')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Main Page Banner" parent="Utilities" />
                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header" style={{ paddingBottom: '20px' }}>
                                    <h5>Add Main Page Banner</h5>
                                </div>
                                <div className="card-body" style={{ paddingTop: '10px' }}>
                                    <div style={{ display: this.state.addorupdate == 'Add Banner' ? '' : 'none' }}>
                                        <form onSubmit={this.addBannerFn}>
                                            <label className='mt-3'>Title : </label>
                                            <input style={{ maxWidth: '350px' }} className="form-control" id="newTitle" name="newTitle" onChange={(e) => {
                                                this.setState({ newTitle: e.target.value })
                                            }} required />

                                            <label className='mt-3'>Sub-Title : </label>
                                            <input style={{ maxWidth: '350px' }} className="form-control" id="newSubTitle" name="newSubTitle" onChange={(e) => {
                                                this.setState({ newSubTitle: e.target.value })
                                            }} required />

                                            <label className='mt-3'>URL : </label>
                                            <input style={{ maxWidth: '350px' }} className="form-control" id="newUrl" name="newUrl" onChange={(e) => {
                                                this.setState({ newUrl: e.target.value })
                                            }} required />

                                            <label className='mt-3'>Select Banner : </label>
                                            <input type='file' style={{ maxWidth: '350px' }} className="form-control" id="newBanner" name="newBanner" onChange={(e) => {
                                                this.setState({ newBanner: e.target.files[0] })
                                            }} required />
                                            <button className="btn btn-primary mt-3">
                                                Add
                                            </button>
                                        </form>
                                    </div>
                                    <hr />
                                    <div className="card-body order-datatable lists-custom">
                                        {
                                            this.state.bannerData && this.state.bannerData.length ?
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Sr. No.</th>
                                                            <th>Title</th>
                                                            <th>Sub-Title</th>
                                                            <th>Banner Image</th>
                                                            <th>URL</th>
                                                            <th>Delete Banner</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.bannerData.map((item, index) =>
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item.title}</td>
                                                                    <td>{item.subtitle}</td>
                                                                    <td><img src={`${process.env.REACT_APP_BASE_URL}` + item.image} style={{ width: '180px' }} /></td>
                                                                    <td>{item.url}</td>
                                                                    <td>
                                                                        <a href='#' onClick={(e) => this.deleteBannerFn(e, item.id)}><i className="fa fa-times" /></a>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                    </tbody>
                                                </table>
                                                :
                                                <div>
                                                    <p>No banner has been added yet.</p>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </Fragment >
        )
    }
}
const mapStateToProps = (state) => ({
    products: state.data.products,
    symbol: state.data.symbol,
})

export default connect(mapStateToProps)(LandingPageBanner);