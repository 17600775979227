import { Link } from 'react-router-dom';
import React, { Component, Fragment } from 'react';
import axios from "axios";
import Breadcrumb from '../common/breadcrumb';
import data from '../../assets/data/listVendor';
import Datatable from '../common/datatable'
import { fetchCoponCodes, generateCouponCode } from '../ProductFunctions';

var resellerData = []

export class couponCodes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            coupondataData: []
        };
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add("authentication-bg");

        var checkusertoken = localStorage.getItem('usertokenSatyamAdmin');
       
        if (checkusertoken == null) {
            this.props.history.push(`/`)
        } else {
            fetchCoponCodes()
                .then(res => {
                    if (res.status == 200) {
                        // const resellerlist = JSON.parse(localStorage.resellerlist)
                        this.setState({ coupondataData: res.data })
                      
                    } else if (res.status === 401) {
                        this.setState({ resellerData: [] })
                    } else {
                        alert('1Error Occured.. Please Try after some time')
                    }
                })
                .catch((err) => {
                    alert('2Error Occured.. Please Try after some time')
                });
        }
    }
    // this.state.resellerData.map((item, index) => {

    // }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit = (e) => {

        let spinner = document.getElementById("spinneranimid");
        spinner.style.display = "block";

        e.preventDefault();

        const codeData = {
            couponDescription: this.state.couponDesc,
            discount: this.state.discount
        };

        generateCouponCode(codeData)
            .then(res => {
                if (res.status === 200) {
                    alert(`Coupon code generation  Successfull`)
                    //window.location.reload();
                } else if (res.status === 405) {
                    alert(`User account does not exist!!`)
                    spinner.style.display = "none";
                }
            })
            .catch((err) => {
                alert("Something went wrong")
                spinner.style.display = "none";
            });
    }

    removeCouponCode = (e, id) => {
        e.preventDefault()
        if (window.confirm(`Remove this coupon code?`)) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            const data = {
                id: id
            };

            axios
                .post("/users/remove-coupon-code", data)
                .then((res) => {
                    if (res.status == 200) {
                        alert(`Coupon code removed Successfully`)
                        window.location.reload();
                    } else {
                        alert('Something went wrong.. Please Try after some time')
                        window.location.reload()
                    }
                })
                .catch((err) => {
                    alert('Something went wrong.. Please Try after some time')
                    window.location.reload()
                });
        }
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Rellers" parent="List" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>


                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5> Add Universal Coupon Code</h5>
                        </div>
                        <div className="card-body">
                            <form
                                className="needs-validation user-add"
                                noValidate=""
                                onSubmit={this.onSubmit}
                            >

                                <div className="form-group row">
                                    <label className="col-xl-3 col-md-4"><span>*</span> Description</label>
                                    <input className="form-control col-xl-8 col-md-7" required
                                        id="couponDesc"
                                        name="couponDesc"
                                        type="text"
                                        placeholder="Describe Coupon code"
                                        value={this.state.couponDesc}
                                        onChange={this.onChange}
                                    />
                                </div>

                                <div className="form-group row">
                                    <label className="col-xl-3 col-md-4"><span>*</span> Discount</label>
                                    <input className="form-control col-xl-8 col-md-7" required
                                        id="discount"
                                        name="discount"
                                        type="number"
                                        placeholder="Discount in %"
                                        value={this.state.discount}
                                        onChange={this.onChange}
                                    />
                                </div>

                                <div className="offset-xl-3 offset-sm-4">
                                    <button
                                        type="submit"
                                        className="btn btn-primary">
                                        Generate Coupon Code
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>Manage Resellers</h5>
                        </div>
                        <div className="card-body order-datatable lists-custom">
                            {/* <Datatable
                                        multiSelectOption={false}
                                        myData={this.state.orders}
                                        pageSize={10}
                                        pagination={true}
                                        class="-striped -highlight"
                                    /> */}
                            {this.state.coupondataData.length > 0 ?
                                <table>
                                    <thead>
                                        <tr>
                                            <th>First Name</th>
                                            <th>Coupon Code</th>
                                            <th>Discount</th>
                                            <th>Description</th>
                                            {/* <th>Status</th> */}
                                            <th>Created On</th>
                                            <th>Remove code</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.coupondataData.map((item, index) =>

                                            <tr key={index}>

                                                {/* <td>{item.id}</td> */}
                                                <td>{item.id}</td>
                                                <td>{item.couponCode}</td>
                                                {/* <td>{days[new Date(item.delivery_date).getDay()] + ', ' + monthNames[new Date(item.delivery_date).getMonth()] + ' ' + new Date(item.delivery_date).getDate() + ', ' + new Date(item.delivery_date).getFullYear()}</td> */}
                                                <td>{item.discount}</td>
                                                <td>{item.couponDescription}</td>
                                                {/* <td>{item.status}</td> */}

                                                {/* <td>
                                                            <strong>
                                                                ₹&nbsp;{item.orderTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                            </strong>
                                                        </td> */}
                                                <td>{item.created}</td>
                                                <td>
                                                    <a href=''>
                                                        <i className="fa fa-times"
                                                            onClick={(e) => this.removeCouponCode(e, item.id)}
                                                        />
                                                    </a>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                                :
                                <div>
                                    <p>No reseller Data Avaiable.</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default couponCodes
