/**
 * Mocking client-server processing
 */
import axios from "axios";
//import _products from "./data.json";

const TIMEOUT = 100;

const _products =  axios
      .post(`${process.env.PUBLIC_URL}/users/fetchproducts`, {})
      .then(response => { 
        return response.data.data;
      })
      .catch(err => { return err; })

// const _products = axios.get(`https://administer.satyampaithani.in/products`).then((response) => {
//   return response.data;
// });


export default {
  getProducts: (cb, timeout) =>
    setTimeout(() => cb(_products), timeout || TIMEOUT),
  buyProducts: (payload, cb, timeout) =>
    setTimeout(() => cb(), timeout || TIMEOUT),
};
