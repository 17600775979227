import React, { Component, Fragment } from 'react'
import axios from "axios";
import Breadcrumb from '../common/breadcrumb';
import data from '../../assets/data/orders';
import Datatable from '../common/datatable'

var orderData = []

export class Orders extends Component {

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add("authentication-bg");
        // List Reseller GET request
        axios.get(`/users/fetch/orders-list`)
            .then((res) => {
                localStorage.setItem("orderData", JSON.stringify(res.data));
            })
            .catch((error) => {
                //alert('Email Confirmationn Failed.')
            });
    }

    render() {
        orderData = JSON.parse(localStorage.orderData)
        return (
            <Fragment>
                <Breadcrumb title="Orders" parent="Sales" />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Manage Order</h5>
                                </div>
                                <div className="card-body order-datatable">
                                    <Datatable
                                        multiSelectOption={false}
                                        myData={orderData}
                                        pageSize={10}
                                        pagination={true}
                                        class="-striped -highlight"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Orders
