import { Link } from 'react-router-dom';
import React, { Component, Fragment } from 'react';
import axios from "axios";
import Breadcrumb from '../common/breadcrumb';
import data from '../../assets/data/listVendor';
import Datatable from '../common/datatable'
import { fetchResellers } from '../ProductFunctions';

var resellerData = []

export class ResellerList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            resellerData: []
        };
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add("authentication-bg");

        var checkusertoken = localStorage.getItem('usertokenSatyamAdmin');
        if (checkusertoken == null) {
            this.props.history.push(`/`)
        } else {
            fetchResellers()
                .then(res => {
                    if (res.status == 200) {
                        const resellerlist = JSON.parse(localStorage.resellerlist)
                        this.setState({ resellerData: resellerlist })
                    } else if (res.status === 401) {
                        this.setState({ resellerData: [] })
                    } else {
                        alert('1Error Occured.. Please Try after some time')
                    }
                })
                .catch((err) => {
                    alert('2Error Occured.. Please Try after some time')
                });
        }
    }
    // this.state.resellerData.map((item, index) => {

    // }

    removeReseller = (email) => {
        if (window.confirm(`Remove ${email} from Resellers?`)) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const user = {
                email: email
            };

            axios
                .post("/users/remove-reseller", {
                    email: user.email,
                })
                .then((res) => {
                    if (res.status == 200) {
                        //alert(`${user.email} removed as a Reseller`)
                        //window.location.reload();
                        spinner.style.display = "none";
                    } else {
                        alert('Something went wrong.. Please Try after some time')
                    }
                })
                .catch((err) => {
                    alert(`${err}`)
                    spinner.style.display = "none";
                });
        }
    }

    render() {
        // if (resellerData.length > 0) {
        //     resellerData = JSON.parse(localStorage.resellerData)
        // }

        // return (
        //     <Fragment>
        //         <Breadcrumb title="Vendor List" parent="Vendors" />
        //         <div className="container-fluid">
        //             <div className="card">
        //                 <div className="card-header">
        //                     <h5>Reseller List</h5>
        //                 </div>
        //                 <div className="card-body vendor-table coupon-list-delete">
        //                     <Datatable
        //                         multiSelectOption={true}
        //                         myData={resellerData}
        //                         pageSize={10}
        //                         pagination={true}
        //                         class="-striped -highlight"
        //                     />
        //                 </div>
        //             </div>
        //         </div>
        //     </Fragment>
        // )

        return (
            <Fragment>
                <Breadcrumb title="Rellers" parent="List" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Manage Resellers</h5>
                                </div>
                                <div className="card-body order-datatable lists-custom">
                                    {/* <Datatable
                                        multiSelectOption={false}
                                        myData={this.state.orders}
                                        pageSize={10}
                                        pagination={true}
                                        class="-striped -highlight"
                                    /> */}
                                    {this.state.resellerData.length > 0 ?
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>First Name</th>
                                                    <th>Last Name</th>
                                                    <th>Email</th>
                                                    <th>Phone Number</th>
                                                    <th>Joined On</th>
                                                    <th>Deposit Amount</th>
                                                    <th>Coupon Code</th>
                                                    <th>Remove Reseller</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.resellerData.map((item, index) =>
                                                
                                                    <tr key={index}>

                                                        {/* <td>{item.id}</td> */}
                                                        <td>{item.first_name}</td>
                                                        <td>{item.last_name}</td>
                                                        {/* <td>{days[new Date(item.delivery_date).getDay()] + ', ' + monthNames[new Date(item.delivery_date).getMonth()] + ' ' + new Date(item.delivery_date).getDate() + ', ' + new Date(item.delivery_date).getFullYear()}</td> */}
                                                        <td>{item.email}</td>
                                                        <td>{item.phone}</td>
                                                        
                                                        {/* <td>
                                                            <strong>
                                                                ₹&nbsp;{item.orderTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                            </strong>
                                                        </td> */}
                                                        <td>{item.created}</td>
                                                        <td>{item.resellerdeposit}</td>
                                                        <td>{item.couponCode}</td>
                                                        <td>
                                                            <a href=''>
                                                                <i className="fa fa-times"
                                                                    onClick={() => this.removeReseller(item.email)}
                                                                />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        :
                                        <div>
                                            <p>No reseller Data Avaiable.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default ResellerList
