import { Link } from 'react-router-dom';
import React, { Component, Fragment } from 'react'

import Breadcrumb from '../common/breadcrumb';

import { fetchSingleOrderAdmin, changeOrderStatus } from './../ProductFunctions';
import { fetchOrderPayment, updateOrderPayment } from '../../api/order';

export class OrderDetails extends Component {

    constructor(props) {
        super(props)

        this.state = {
            id: "",
            orderid: "",
            display_orderid: "",
            orderdate: "",
            deliverydate: "",
            firstname: "",
            lastname: "",
            address: "",
            city: "",
            district: "",
            state: "",
            pincode: "",
            phone: "",
            email: "",
            subtotal: "",
            shipping: "",
            total: "",
            status: "",
            items: [],
            temp_status: '',
            trackNumber: '',
            fullfilledBy: '',
            shippingCharge: '',
            marginCall: '',
            razorpay_status: ''
        }
    }

    componentDidMount() {
        var checkusertoken = localStorage.getItem('usertokenSatyamAdmin');
        if (checkusertoken == null) {
            this.props.history.push(`/`)
        } else {
            let slug = this.props.match.params.slug;
            const orderDetails = {
                orderid: slug,
            }

            var checkusertoken = localStorage.getItem('usertokenSatyamAdmin');
            if (checkusertoken == null) {
                this.props.history.push(`${process.env.PUBLIC_URL}/login-register`)
            } else {
                this.fetchSingleOrder(orderDetails)
            }
        }
    }

    fetchSingleOrder = (orderDetails) => {
        console.log(orderDetails)
        fetchSingleOrderAdmin(orderDetails)
            .then(res => {
                if (res.status === 200) {
                    if (localStorage.singleOrderSatyamCustomer !== null) {
                        const singleOrder = JSON.parse(localStorage.singleOrderShivshahiAdmin)

                        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
                        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
                        var orderDate = new Date(singleOrder.created);
                        // var deliveryDate = new Date(singleOrder.created);
                        // var orderDateFinal = days[orderDate.getDay()] + ', ' + monthNames[orderDate.getMonth()] + ' ' + orderDate.getDate() + ', ' + orderDate.getFullYear();
                        // var deliveryDateFinal = days[deliveryDate.getDay()] + ', ' + monthNames[deliveryDate.getMonth()] + ' ' + deliveryDate.getDate() + ', ' + deliveryDate.getFullYear();

                        var next5days = new Date(Date.now() + 5 * 86400000);
                        var orderDateFinal = days[orderDate.getDay()] + ', ' + monthNames[orderDate.getMonth()] + ' ' + orderDate.getDate() + ', ' + orderDate.getFullYear();
                        var deliveryDateFinal = days[next5days.getDay()] + ', ' + monthNames[next5days.getMonth()] + ' ' + next5days.getDate() + ', ' + next5days.getFullYear();

                        if (singleOrder.OrderItems !== null) {
                            this.setState({
                                items: JSON.parse(singleOrder.orderItems)
                            })
                        }

                        this.setState({
                            id: singleOrder.id,
                            orderid: singleOrder.order_id,
                            orderdate: orderDateFinal,
                            // deliverydate: deliveryDateFinal,
                            firstname: singleOrder.firstname,
                            lastname: singleOrder.lastname,
                            address: singleOrder.address,
                            city: singleOrder.city,
                            //district: singleOrder.district,
                            state: singleOrder.state,
                            pincode: singleOrder.pincode,
                            phone: singleOrder.phone,
                            email: singleOrder.email,
                            subtotal: singleOrder.orderTotal,
                            shipping: singleOrder.shipping,
                            total: singleOrder.orderTotal,
                            status: singleOrder.status,
                            temp_status: singleOrder.status,
                            trackNumber: singleOrder.trackingNumber,
                            fullfilledBy: singleOrder.trackingProvider,
                            shippingCharge: singleOrder.shippingCharge,
                            // items: JSON.parse(singleOrder.items),
                            payment_mode: singleOrder.payment_mode,
                            payment_status: singleOrder.payment_status,
                            marginCall: singleOrder.marginCall
                        })
                    }
                } else if (res.status === 401) {
                    alert('Wrong order ID..!')
                    this.props.history.push(`/orders`)
                } else {
                    alert('Something Went Wrong')
                    this.props.history.push(`/orders`)
                }
            })
            .catch((err) => {
                alert('Something Went Wrong')
                this.props.history.push(`/orders`)
            });
    }

    onChange = (e) => {
        this.setState({ temp_status: e.target.value })
    }

    onShippingStatusChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onPaymentStatus = (e) => {
        fetchOrderPayment({ orderId: this.state.orderid })
            .then(response => {
                let items = response.data.items
                if (items && items.length >= 1) {
                    let status = items[0].status
                    if (status) {
                        this.setState({ razorpay_status: status })
                        if (status == 'captured') {
                            let data = {
                                payment_status: 'Completed',
                                order_id: this.state.id
                            }
                            updateOrderPayment(data)
                                .then(response => {
                                    let slug = this.props.match.params.slug;
                                    const orderDetails = {
                                        orderid: slug,
                                    }
                                    this.fetchSingleOrder(orderDetails)
                                })
                                .catch(error => {
                                    console.log(error);
                                })
                        } else {
                            alert('Failed to get Razorpay payment details')
                            console.log('error');
                        }
                    } else {
                        alert('Failed to get Razorpay payment details')
                        console.log('error')
                    }
                } else {
                    alert('Failed to get Razorpay payment details')
                    console.log('error')
                }
            })
            .catch(error => {
                alert('Failed to get Razorpay payment details')
                console.log(error)
            })
    }

    statusChange = (e) => {
        e.preventDefault();

        const orderData = {
            id: this.state.id,
            orderid: this.state.orderid,
            status: this.state.temp_status,
            email: this.state.email,
            phone: this.state.phone,
            payment_mode: this.state.payment_mode,
            marginCall: this.state.marginCall,
            trackDetails: this.state.trackNumber,
            fullfilledBy: this.state.fullfilledBy
        }


        if (this.state.temp_status != this.state.status) {
            if (window.confirm('Do you want to update the status ?')) {
                changeOrderStatus(orderData)
                    .then((res) => {
                        if (res.status == 200) {
                            alert('Status Updated ..!')
                            window.location.reload()
                        } else {
                            alert('Something Went Wrong ..!')
                        }
                    })
                    .catch((err) => {
                        alert('Something Went Wrong ..!')
                    })
            }
        } else {
            window.location.reload()
        }
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Order Details" parent="Sales" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Order ID:&nbsp;&nbsp;{this.state.id}</h5>
                                </div>
                                <div className="card-body order-datatable lists-custom">

                                    <div className="custom-order-details">
                                        <h4>Order Details</h4>
                                        <ul>
                                            <li>Order ID :&nbsp;&nbsp;<span>{this.state.orderid}</span></li>
                                            <li>Order Date :&nbsp;&nbsp;<span>{this.state.orderdate}</span></li>
                                            {/* <li>Expected Delivery :&nbsp;&nbsp;<span>{this.state.deliverydate}</span></li> */}
                                            <li>Subtotal :&nbsp;&nbsp;<span>₹{(this.state.subtotal) - this.state.shippingCharge}</span></li>
                                            <li>Shipping :&nbsp;&nbsp;<span>₹{this.state.shippingCharge}</span></li>
                                            <li>Total :&nbsp;&nbsp;<span>₹{this.state.total}</span></li>
                                            <li>Payment mode :&nbsp;&nbsp;<span>{this.state.payment_mode}</span></li>
                                            <li>Payment Status :&nbsp;&nbsp;<span style={{ color: this.state.payment_status == 'Pending' ? 'red' : 'green' }}>{this.state.payment_status}</span></li>
                                            <li>COD Margin :&nbsp;&nbsp;<span>₹{this.state.marginCall}</span></li>
                                            {
                                                this.state.payment_mode == 'razorpay' ?
                                                    <li >Razorpay Status :&nbsp;&nbsp;<span>{this.state.razorpay_status}</span></li>
                                                    : <div></div>
                                            }
                                        </ul>

                                        {
                                            this.state.payment_mode == 'razorpay' ?
                                                <button className="btn btn-primary" style={{ marginTop: '10px' }} onClick={(e) => { this.onPaymentStatus(e) }}>
                                                    Fetch Payment
                                                </button>
                                                : <div></div>
                                        }

                                        <h4 className="pt-3">Shipping Address</h4>
                                        <ul className="pb-3">
                                            <li>{this.state.firstname}&nbsp;&nbsp;{this.state.lastname}</li>
                                            <li>{this.state.address}, {this.state.city}, Dist: {this.state.district},</li>
                                            <li>{this.state.state}, {this.state.pincode}.</li>
                                            <li className="imp">{this.state.phone}</li>
                                            <li className="imp">{this.state.email}</li>
                                        </ul>


                                        <hr />
                                        <h4>Order Details</h4>
                                        <div className="mb-4">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className='first'>Product</th>
                                                        <th>Price</th>
                                                        <th>Size</th>
                                                        <th>Quantity</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.items.map((item, index) =>
                                                        <tr key={index}>
                                                            <td className='first'>
                                                                <a href={`${process.env.PUBLIC_URL}/products/physical/product-detail/${item.id}`} target="_blank" rel="noopener noreferrer">
                                                                    <figure>
                                                                        <img style={{ width: '100px' }} src={`${process.env.REACT_APP_BASE_URL}${item.image[0]}`} alt="Product" />
                                                                    </figure>
                                                                    <h5 className="product-title pl-5">
                                                                        {item.name}
                                                                    </h5>
                                                                </a>
                                                            </td>

                                                            <td>
                                                                ₹&nbsp;
                                                                {item.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                }
                                                            </td>

                                                            <td>{item.selectedProductSize}</td>

                                                            <td>{item.quantity}</td>

                                                            <td>
                                                                <strong>
                                                                    ₹&nbsp;
                                                                    {item.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                    }
                                                                </strong>
                                                            </td>

                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <hr />
                                        <div className="order-statuss">
                                            <h4>Current Order Status : <strong>{this.state.status}</strong></h4>

                                            <form onSubmit={this.statusChange} >
                                                <select className="form-control" id="statuschange" value={this.state.temp_status} onChange={this.onChange}>
                                                    <option value="placed">Placed</option>
                                                    <option value="packed">Packed</option>
                                                    <option value="shipped">Shipped</option>
                                                    <option value="dispatched">Dispatched</option>
                                                    <option value="delivered">Delivered</option>
                                                    <option value="cancelled">Cancelled</option>
                                                </select>

                                                {this.state.temp_status == "shipped" ?

                                                    <div className="form-group mb-3 row">
                                                        <label className="col-xl-3 col-sm-4 mb-0">
                                                            Tracking Number:
                                                        </label>
                                                        <div className="col-xl-8 col-sm-7">
                                                            <input
                                                                className="form-control"
                                                                name="trackNumber"
                                                                id="trackNumber"
                                                                type="text"
                                                                placeholder="Enter Tracking Number"
                                                                required
                                                                value={this.state.trackNumber}
                                                                onChange={this.onShippingStatusChange}
                                                            />
                                                        </div>

                                                        <div className="form-group mb-3 row" style={{ marginLeft: "84px", marginTop: "8px" }}>
                                                            <label className="col-xl-3 col-sm-4 mb-0">
                                                                Fullfilled By:
                                                            </label>
                                                            <div className="col-xl-8 col-sm-7">
                                                                <input
                                                                    className="form-control"
                                                                    name="fullfilledBy"
                                                                    id="fullfilledBy"
                                                                    type="text"
                                                                    placeholder="Courier Provider"
                                                                    required
                                                                    value={this.state.fullfilledBy}
                                                                    onChange={this.onShippingStatusChange}
                                                                />
                                                            </div>

                                                        </div>

                                                    </div>
                                                    :
                                                    <div className="empty-list">

                                                    </div>
                                                }

                                                <button type="submit" className="btn btn-primary" style={{ height: "38px" }}>
                                                    Update Status
                                                </button>

                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div >

            </Fragment >
        )

    }
}

export default OrderDetails
