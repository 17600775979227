import React, { Component, Fragment } from 'react'
import Breadcrumb from '../../common/breadcrumb';
import Modal from 'react-responsive-modal';
import 'react-toastify/dist/ReactToastify.css';
import { fetchCategoryData, addSubCategory, editCategory, deleteSubCategory, addColor, fetchColor, updateColor, deleteColor } from "../physical/product-functions/product-functions";
// import data from '../../../assets/data/category';
// import Datatable from '../../common/datatable';


export class ManageColors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            colorData: [],
            addorupdate: 'Add Color',
            newColor: '',
            colorToUpdate: '',
            updatedColor: ''
        };
    }

    componentDidMount() {
        var checkusertoken = localStorage.getItem('usertokenSatyamAdmin');

        if (checkusertoken == null) {
            this.props.history.push(`/`)
        } else {
            fetchColor()
                .then(res => {
                    if (res.status == 200) {
                        this.setState({
                            colorData: res.data.data,
                            colorToUpdate: res.data.data[0] ? res.data.data[0].color : ''
                        })
                    } else {
                        alert('Error occured.. Please try after some time')
                    }
                })
                .catch((err) => {
                    alert(err)
                })
        }
    }

    addColorFn = (e) => {
        e.preventDefault();
        if (window.confirm('Add Color?')) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            const data = {
                color: this.state.newColor,
            }
            addColor(data)
                .then(res => {
                    if (res.data.status == 200) {
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.data.status == 400) {
                        alert(res.data.msg)
                        spinner.style.display = "none";
                    } else {
                        alert('Error occured while adding color.. Please try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error occured while adding color.. Please try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    updateColorFn = (e) => {
        e.preventDefault();
        if (window.confirm('Update Color?')) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            const data = {
                colorToUpdate: this.state.colorToUpdate,
                updatedColor: this.state.updatedColor,
            }
            updateColor(data)
                .then(res => {
                    if (res.data.status == 200) {
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.data.status == 400) {
                        alert(res.data.msg)
                        spinner.style.display = "none";
                    } else {
                        alert('Error occured while updating color.. Please try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error occured while updating color.. Please try after some time1')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    deleteColorFn = (e, color) => {
        e.preventDefault();
        if (window.confirm('Delete Color?')) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            const data = { color: color }
            deleteColor(data)
                .then(res => {
                    if (res.data.status == 200) {
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.data.status == 400) {
                        alert(res.data.msg)
                        spinner.style.display = "none";
                    } else {
                        alert('Error occured while deleting color.. Please try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error occured while deleting color.. Please try after some time1')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Color" parent="Shop" />
                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Add or Update Color</h5>
                                </div>
                                <div className="card-header">
                                    <select className="form-control" id="addorupdate" name="addorupdate" value={this.state.addorupdate} onChange={(e) => this.setState({ addorupdate: e.target.value })} style={{ maxWidth: '250px' }}>
                                        <option value="Add Color">Add Color </option>
                                        <option value="Update Color">Update Color</option>
                                    </select>
                                    <hr />

                                    <div style={{ display: this.state.addorupdate == 'Add Color' ? '' : 'none' }}>
                                        <h5>Add Color</h5>
                                        <form onSubmit={this.addColorFn}>
                                            <label className='mt-3'>New Color : </label>
                                            <input style={{ maxWidth: '350px' }} className="form-control" id="newColor" name="newColor" onChange={(e) => this.setState({ newColor: e.target.value })} required />
                                            <button type="submit" className="btn btn-primary mt-3">
                                                Add
                                            </button>
                                        </form>
                                    </div>

                                    <div style={{ display: this.state.addorupdate == 'Update Color' ? '' : 'none' }}>
                                        <h5>Update Color</h5>
                                        <form onSubmit={this.updateColorFn}>
                                            <label className='mt-3'>Select color : </label>
                                            <select
                                                required
                                                className="form-control mb-0"
                                                name="addInCategory"
                                                id="addInCategory"
                                                onChange={(e) => {
                                                    this.setState({ colorToUpdate: e.target.value })
                                                }}
                                            >
                                                {
                                                    this.state.colorData ?
                                                        this.state.colorData.map((colorObj) => {
                                                            return <option value={colorObj.color}>{colorObj.color}</option>;
                                                        })
                                                        : ""
                                                }
                                            </select>
                                            <label className='mt-3'>New Color : </label>
                                            <input style={{ maxWidth: '350px' }} className="form-control" id="updatedSubCategory" name="updatedSubCategory" onChange={(e) => this.setState({ updatedColor: e.target.value })} required />
                                            <button type="submit" className="btn btn-primary mt-3">
                                                Update
                                            </button>
                                        </form>
                                    </div>

                                    <div className="card-body order-datatable lists-custom">
                                        {
                                            this.state.colorData ?
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Sr. No.</th>
                                                            <th>Color</th>
                                                            <th>Delete Color</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.colorData.map((item, index) =>
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{item.color}</td>
                                                                <td>
                                                                    <a href='#' onClick={(e) => this.deleteColorFn(e, item.color)}><i className="fa fa-times" /></a>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                :
                                                <div>
                                                    <p>No Color has been added yet.</p>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default ManageColors