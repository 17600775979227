import axios from 'axios';

export const fetchTransactions = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/users/fetchtransactions`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchOrderPayment = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/razorpayorderpayment`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const updateOrderPayment = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/users/updateorderpayment`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};