import axios from "axios";

//fetchTestimonialData
export const fetchShowoffGallery = (newUser) => {
    return axios
        .post(process.env.PUBLIC_URL + "/users/fetchShowoffGallery", {

        })
        .then(res => {
            //alert(JSON.stringify(res.data))
            return res;
        })
        .catch(err => {
            return err;
            //alert('Registration Failed!! Something went wrong.')
        });
};


//fetchNewTestimonialData
export const fetchNewTestimonial = (newUser) => {
    return axios
        .post(process.env.PUBLIC_URL + "/users/fetchNewTestimonial", {

        })
        .then(res => {
            //alert(JSON.stringify(res.data))
            return res;
        })
        .catch(err => {
            return err;
            //alert('Registration Failed!! Something went wrong.')
        });
};

//fetchTestimonialData
export const fetchTestimonialData = (newUser) => {
    return axios
        .post(process.env.PUBLIC_URL + "/users/fetchTestimonialData", {

        })
        .then(res => {
            //alert(JSON.stringify(res.data))
            return res;
        })
        .catch(err => {
            return err;
            //alert('Registration Failed!! Something went wrong.')
        });
};
