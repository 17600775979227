import { FETCH_CATEGORY_DATA } from "../constants/ActionTypes";

const initState = {
  cats: [],
};

const fetchCatsSuccess = cats => ({
  type: FETCH_CATEGORY_DATA,
  payload: cats
});

// fetch cats
export const fetchCats = cats => {
  return dispatch => {
    dispatch(fetchCatsSuccess(cats));
  };
};

const catsReducer = (state = initState, action) => {
  if (action.type === FETCH_CATEGORY_DATA) {
    return {
      ...state,
      cats: action.payload
    };
  }

  return state;
};

export default catsReducer;
