import React, { Component, Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'

import logomonogram from './../../../assets/images/logo/logomonogram.png'

export class User_menu extends Component {

    logOut = e => {
        e.preventDefault()
        localStorage.removeItem('usertokenSatyamAdmin')
        localStorage.removeItem('resellerData')
        this.props.history.push('/')
    }

    render() {
        return (
            <Fragment>
                <div className="onhover-dropdown">
                    <div className="media" style={{ justifyContent: 'flex-end' }}>
                        <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={logomonogram} alt="header-user" />
                        {/* <div className="dotted-animation"><span className="animate-circle"></span><span className="main-circle"></span></div> */}
                    </div>
                    <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                        <li><Link to={`${process.env.PUBLIC_URL}/settings/profile`} ><i data-feather="user"></i>Profile</Link></li>
                        {/* <li><a href="javascript:void(0)"><i data-feather="mail"></i>Inbox</a></li>
                        <li><a href="javascript:void(0)"><i data-feather="lock"></i>Lock Screen</a></li>
                        <li><a href="javascript:void(0)"><i data-feather="settings"></i>Settings</a></li> */}
                        <li><a href="" onClick={this.logOut}>
                            Log Out
                            </a>
                        </li>
                    </ul>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(User_menu);
