import axios from "axios";

// CREATE new product item
export const create = (newProd) => {
  return axios
    .post(process.env.PUBLIC_URL + "users/add-product", {
      sku: newProd.sku,
      name: newProd.name,
      price: newProd.price,
      finalprice: newProd.finalPrice,
      discount: newProd.discount,
      resellerprice: newProd.resellerPrice,
      resellerdiscount: newProd.resellerDiscount,
      category: [
        newProd.category
      ],
      subcategory: [
        newProd.subcategory
      ],
      variation: [
        {
          size: [
            {
              name: "6 yards",
              stock: newProd.stock6
            },
            {
              name: "9 yards",
              stock: newProd.stock9
            }
          ]
        }
      ],
      soldout: newProd.soldout,
      image: newProd.imageFiles,
      fullDescription: newProd.content,
      Weight: newProd.weight,
      Materials: newProd.material,
      BlousePiece: newProd.blousepiece,
      FabricCare: newProd.fabriccare
    })
    .then((res) => {
      alert("Product Added");
      return res
    })
    .catch(err => {
      return err.response;
    });
};

// UPDATE
export const updateProduct = (update) => {
  return axios
    .post(`${process.env.PUBLIC_URL}/users/update-product`, {
      id: update.id,
      sku: update.sku,
      name: update.name,
      price: update.price,
      finalprice: update.finalPrice,
      discount: update.discount,
      resellerprice: update.resellerPrice,
      resellerdiscount: update.resellerDiscount,
      // available: update.available,
      // preorder: update.preorder,
      //rating: update.rating,
      category: [
        update.category
      ],
      subcategory: [
        update.subcategory
      ],
      variation: [
        {
          size: [
            {
              name: "6 yards",
              stock: update.stock6
            },
            {
              name: "9 yards",
              stock: update.stock9
            }
          ]
        }
      ],
      soldout: update.soldout,
      image: update.imageFiles,
      fullDescription: update.content,
      Weight: update.weight,
      //Dimensions: update.dimensions,
      Materials: update.material,
      BlousePiece: update.blousepiece,
      FabricCare: update.fabriccare,
      //OtherInfo: update.otherinf
    })
    .then((res) => {
      alert("Product Updated");
      return res
    })
    .catch(err => {
      return err.response;
    });
};

// Delete
export const deleteProd = (productData) => {
  return axios.all([
    axios.delete(`https://administer.satyampaithani.in/products/${productData.Itemid}`, {

    }),
    axios.post(process.env.PUBLIC_URL + "/users/delete-productImages", {
      id: productData.Itemid,
      image: productData.image
    })
  ])
    .then(axios.spread((data1, data2) => {
      return (data1, data2)
    }))
    .catch(err => {
      return err.response;
    });


  // return axios
  //   .delete(`https://administer.satyampaithani.in/products/${productData.Itemid}`, {

  //   })
  //   .then((res) => {
  //     alert("Item Deleted");
  //     return res
  //   });
};


//fetch all category data
export const fetchCategoryData = async () => {
  return axios
    .post(process.env.PUBLIC_URL + "users/fetchCategoryData", {

    })
    .then((res) => {
      // localStorage.setItem("categoryDataMurtikaarAdmin", JSON.stringify(res.data));
      return res;
    })
    .catch(err => {
      return err.response;
    });
};

export const addSubCategory = async (subcategoryData) => {
  return axios
    .post(process.env.PUBLIC_URL + "users/addSubCategory", {
      category: subcategoryData.category,
      subcategory: subcategoryData.subcategory
    })
    .then((res) => {
      // localStorage.setItem("categoryDataMurtikaarAdmin", JSON.stringify(res.data));
      return res;
    })
    .catch(err => {
      return err.response;
    });
};

export const editCategory = async (subcategoryData) => {
  return axios
    .post(process.env.PUBLIC_URL + "users/updateCategory", {
      oldSubCategory: subcategoryData.oldSubCategory,
      updatedSubCategory: subcategoryData.updatedSubCategory
    })
    .then((res) => {
      // localStorage.setItem("categoryDataMurtikaarAdmin", JSON.stringify(res.data));
      return res;
    })
    .catch(err => {
      return err.response;
    });
};

export const deleteSubCategory = async (data) => {
  return axios
    .post(process.env.PUBLIC_URL + "users/deleteSubCategory", {
      id: data.id
    })
    .then((res) => {
      // localStorage.setItem("categoryDataMurtikaarAdmin", JSON.stringify(res.data));
      return res;
    })
    .catch(err => {
      return err.response;
    });
};

export const addColor = async (data) => {
  return axios
    .post(process.env.PUBLIC_URL + "/productrequest/addcolor", data)
    .then((res) => { return res; })
    .catch(err => { return err.response; });
};

export const updateColor = async (data) => {
  return axios
    .post(process.env.PUBLIC_URL + "/productrequest/updatecolor", data)
    .then((res) => { return res; })
    .catch(err => { return err.response; });
};

export const deleteColor = async (data) => {
  return axios
    .post(process.env.PUBLIC_URL + "/productrequest/deletecolor", data)
    .then((res) => { return res; })
    .catch(err => { return err.response; });
};

export const fetchColor = async (data) => {
  return axios
    .post(process.env.PUBLIC_URL + "/productrequest/fetchcolor", data)
    .then((res) => { return res; })
    .catch(err => { return err.response; });
};

export const fetchPrdFilterColor = async (data) => {
  return axios
    .post(process.env.PUBLIC_URL + "/productrequest/fetchPrdFilterColor", data)
    .then((res) => { return res; })
    .catch(err => { return err.response; });
};

export const addVariant = async (data) => {
  return axios
    .post(process.env.PUBLIC_URL + "/productrequest/addVariant", data)
    .then((res) => { return res; })
    .catch(err => { return err.response; });
};

export const updateVariant = async (data) => {
  return axios
    .post(process.env.PUBLIC_URL + "/productrequest/updateVariant", data)
    .then((res) => { return res; })
    .catch(err => { return err.response; });
};

export const deleteVariant = async (data) => {
  return axios
    .post(process.env.PUBLIC_URL + "/productrequest/deleteVariant", data)
    .then((res) => { return res; })
    .catch(err => { return err.response; });
};

export const fetchVariant = async (data) => {
  return axios
    .post(process.env.PUBLIC_URL + "/productrequest/fetchVariant", data)
    .then((res) => { return res; })
    .catch(err => { return err.response; });
};

export const addDeal = async (data) => {
  return axios
    .post(process.env.PUBLIC_URL + "/productrequest/addDeal", data)
    .then((res) => { return res; })
    .catch(err => { return err.response; });
};

export const updateDeal = async (data) => {
  return axios
    .post(process.env.PUBLIC_URL + "/productrequest/updateDeal", data)
    .then((res) => { return res; })
    .catch(err => { return err.response; });
};

export const deleteDeal = async (data) => {
  return axios
    .post(process.env.PUBLIC_URL + "/productrequest/deletedeal", data)
    .then((res) => { return res; })
    .catch(err => { return err.response; });
};

export const fetchDeal = async (data) => {
  return axios
    .post(process.env.PUBLIC_URL + "/productrequest/fetchDeal", data)
    .then((res) => { return res; })
    .catch(err => { return err.response; });
};

export const deletetMultipleProducts = async (data) => {
  return axios
    .post(process.env.PUBLIC_URL + "/productrequest/deletemultipleproducts", data)
    .then((res) => { return res; })
    .catch(err => { return err.response; });
};

export const addPriceTag = async (data) => {
  return axios
    .post(process.env.PUBLIC_URL + "/productrequest/addPriceTag", data)
    .then((res) => { return res; })
    .catch(err => { return err.response; });
};

export const updatePriceTag = async (data) => {
  return axios
    .post(process.env.PUBLIC_URL + "/productrequest/updatePriceTag", data)
    .then((res) => { return res; })
    .catch(err => { return err.response; });
};

export const deletePriceTag = async (data) => {
  return axios
    .post(process.env.PUBLIC_URL + "/productrequest/deletePriceTag", data)
    .then((res) => { return res; })
    .catch(err => { return err.response; });
};

export const fetchPriceTag = async (data) => {
  return axios
    .post(process.env.PUBLIC_URL + "/productrequest/fetchPriceTag", data)
    .then((res) => { return res; })
    .catch(err => { return err.response; });
};

export const addBanner = async (data) => {
  return axios
    .post(process.env.PUBLIC_URL + "/otherrequest/addlandingbanner", data)
    .then((res) => { return res; })
    .catch(err => { return err.response; });
};

export const updateBanner = async (data) => {
  return axios
    .post(process.env.PUBLIC_URL + "/otherrequest/updatelandingbanner", data)
    .then((res) => { return res; })
    .catch(err => { return err.response; });
};

export const deleteBanner = async (data) => {
  return axios
    .post(process.env.PUBLIC_URL + "/otherrequest/deletelandingbanner", data)
    .then((res) => { return res; })
    .catch(err => { return err.response; });
};

export const fetchBanner = async (data) => {
  return axios
    .post(process.env.PUBLIC_URL + "/otherrequest/fetchlandingbanner", data)
    .then((res) => { return res; })
    .catch(err => { return err.response; });
};