import React, { Component, Fragment } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User } from "react-feather";
// import { User, Unlock } from "react-feather";
import { withRouter } from "react-router-dom";
import { login } from "./Userfunctions";

export class LoginTabset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeShow: true,
      startDate: new Date(),
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    var checkusertoken = localStorage.getItem('usertokenSatyamAdmin');
    if (checkusertoken == 'usertokenSatyamAdmin') {
      this.props.history.push(`/dashboard`)
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit = (e) => {
    e.preventDefault();

    let spinner = document.getElementById("spinneranimid");
    spinner.style.display = "block";

    const user = {
      username: this.state.username,
      password: this.state.password,
    };

    login(user)
      .then((res) => {
        if (res.status == 200) {
          this.props.history.push('/dashboard');
        } else if (res.status == 405 || res.status == 402) {
          alert("Username or password is Incorrect")
          spinner.style.display = "none";
        } else {
          alert("Something went wrong")
          spinner.style.display = "none";
        }
      })
      .catch((err) => {
        alert("Something went wrong")
        spinner.style.display = "none";
      })
  }


  handleChange(date) {
    this.setState({
      startDate: date,
    });
  }

  // routeChange = () => {
  //   this.props.history.push(`${process.env.PUBLIC_URL}/dashboard`);
  // };
  render() {
    return (
      <div>
        <Fragment>
          <div id="spinneranimid" className="overlay-spinner">
            <div className="cssload-whirlpool" />
          </div>
          <Tabs>
            <TabList className="nav nav-tabs tab-coupon">
              <Tab className="nav-link" >
                <User />
                Login
              </Tab>
              {/* <Tab className="nav-link" onClick={(e) => this.clickActive(e)}>
                <Unlock />
                Register
              </Tab> */}
            </TabList>

            <TabPanel>
              <form className="form-horizontal auth-form"
                onSubmit={this.onSubmit}>
                <div className="form-group">
                  <input
                    required
                    name="username"
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    id="username"
                    value={this.state.username}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    required
                    name="password"
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-terms">
                  <div className="custom-control custom-checkbox mr-sm-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customControlAutosizing"
                    />
                    {/* <label className="d-block">
                      <input
                        className="checkbox_animated"
                        id="chk-ani2"
                        type="checkbox"
                      />
                      Reminder Me{" "}
                      <span className="pull-right">
                        {" "}
                        <a href="#" className="btn btn-default forgot-pass p-0">
                          lost your password
                        </a>
                      </span>
                    </label> */}
                  </div>
                </div>
                <div className="form-button">
                  <button
                    className="btn btn-primary"
                    type="submit"
                  // onClick={() => this.routeChange()}
                  >
                    Login
                  </button>
                </div>
              </form>
            </TabPanel>
          </Tabs>
        </Fragment>
      </div>
    );
  }
}

export default withRouter(LoginTabset);
