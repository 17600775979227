/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import axios from "axios";
import { MultiSelect } from 'react-multi-select-component';

import { fetchColor } from "../physical/product-functions/product-functions";

// import { create } from "../physical/product-functions/product-functions";
import { addProduct } from "./../../../api/product";

import Breadcrumb from "../../common/breadcrumb";

import one from "../../../assets/images/pro3/1.jpg";
import user from "../../../assets/images/user.png";

export class AddProduct extends Component {
  constructor(props) {
    super(props);
    var products = this.props.products

    this.state = {
      sku: '',
      soldOut: 'false',
      sale: true,
      content: '',
      color: '',
      stock6: '',
      stock9: '',
      category: '',
      subcategory: '',
      selectedView: '-Select Category-',
      material: '',
      blousepiece: '',
      fabriccare: '',
      file: [
      ],
      image: [
        {},
        {},
        {},
        {},
        {},
        {}
      ],
      imgfiles: [
        {},
        {},
        {},
        {},
        {},
        {}
      ],
      filenames: [
        {},
        {},
        {},
        {},
        {},
        {}
      ],
      variants: [
        {},
        {},
        {},
        {},
        {},
        {}
      ],
      reader: [one],
      dummyimgs: [
        { img: user },
        { img: user },
        { img: user },
        { img: user },
        { img: user },
        { img: user }
      ],
      // variation: [
      //   {

      //   }
      // ]
      categories: [],
      subcategories: [],
      colors: [],
      filterColors: []
    };
  };

  componentDidMount = async () => {
    var checkusertoken = localStorage.getItem('usertokenSatyamAdmin');
    if (checkusertoken === null) {
      this.props.history.push(`/`)
    } else {
      var subcategoriestest = [{ value: ["-Select Category-"], name: '-Select Category-' }]
      await fetchColor()
        .then(res => {
          if (res.status == 200) {
            var colors = res.data.data
            var colorsForMultiSelect = []
            for (var i = 0; i < colors.length; i++) {
              var color = colors[i]
              colorsForMultiSelect.push({ label: color.color, value: color.color })
            }
            this.setState({ colors: colorsForMultiSelect })
          } else {
            alert('Error occured.. Please try after some time')
          }
        })
        .catch((err) => {
          alert(err)
        })

      let cats = this.props.categoryData;
      var catArr = []
      for (var i = 0; i < cats.length; i++) {
        catArr.push(cats[i].category)
      }

      let uniqueCats = [...new Set(catArr)];
      var ArrFinal = []
      for (var j = 0; j < uniqueCats.length; j++) {
        var subcat = cats.filter((hero) =>
          hero.category == uniqueCats[j]
        )

        var subcatArrFinal = []
        for (var k = 0; k < subcat.length; k++) {
          subcatArrFinal.push(subcat[k].subcategory)
        }

        ArrFinal.push({
          id: j + 1,
          cat: uniqueCats[j],
          subcats: subcatArrFinal
        })

        var subcategories = []
        var categories = []
        for (var i = 0; i < ArrFinal.length; i++) {
          categories.push(ArrFinal[i].cat);
          subcategories.push(ArrFinal[i].subcats);
        }
      }

      for (let i = 0, j = 0; i < subcategories.length, j < categories.length; i++, j++) {
        let initvalue = ["-Select Sub-Category-"]
        let sub = subcategories[i]
        const finalvalue = initvalue.concat(sub)

        let subcatobject = {
          value: finalvalue, name: categories[j]
        }
        subcategoriestest.push(subcatobject)
      }

      this.setState({
        categories: categories,
        subcategories: subcategoriestest

      })
    }
  }

  updateContent(newContent) {
    this.setState({
      content: newContent
    })
  }

  ontxtChange = (evt) => {
    var newContent = evt.editor.getData();
    this.setState({
      content: newContent
    })
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    if (e.target.name == 'subcat') {
      this.setState({
        subcategory: e.target.value
      });
    }
  }

  _handleImgChange = (e, i) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    const { dummyimgs } = this.state;
    const { filenames } = this.state;
    const { imgfiles } = this.state;
    const path = '/assets/img/product/fashion/';

    reader.onloadend = () => {
      dummyimgs[i].img = reader.result;
      filenames[i] = path + file.name;

      imgfiles[i] = file
      this.setState({
        file: file,
        filenames,
        dummyimgs,
        imgfiles,
        reader: reader.result,
      });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }


  getSubcategory = () => {
    try {
      let subcategories = this.state.subcategories
      const view = subcategories.filter(({ name }) => name === this.state.selectedView)[0]

      return (
        <div>
          <select
            required
            className="form-control mb-0"
            name="subcat"
            id="subcat"
            value={this.state.subcat}
            onChange={this.onChange}
          >
            {view.value.map((m, index) => <option value={m} key={index}>{m}</option>)}
          </select>
        </div>
      )
    }
    catch (err) {
      console.log(err);
    }
  }

  handleValidSubmit = async (e) => {
    e.preventDefault();
    let spinner = document.getElementById("spinneranimid");

    if (window.confirm('Add Product?')) {
      spinner.style.display = "block";
      var array1 = this.state.filenames
      var imageFiles = array1.filter(value => Object.keys(value).length !== 0);

      function discount(value1, value2) {
        return ((value1 - value2))
      }

      if (this.state.resellerprice) {
        var resellerDiscount = await discount(this.state.price, this.state.resellerprice)

        this.setState({
          resellerDiscount: resellerDiscount
        })
      }

      const newProd = {
        sku: this.state.sku,
        name: this.state.name,
        scratchedPrice: this.state.scratchedPrice,
        price: this.state.price,
        resellerprice: this.state.resellerprice,
        resellerdiscount: this.state.resellerDiscount,
        category: [
          this.state.category
        ],
        subcategory: [
          this.state.subcategory
        ],
        variation: [
          {
            color: "white",
            size: [
              {
                name: "6 yards",
                stock: this.state.stock6
              },
              {
                name: "9 yards",
                stock: this.state.stock9
              }
            ]
          }
        ],
        soldout: this.state.soldOut,
        image: imageFiles,
        fulldescription: this.state.content,
        weight: this.state.weight,
        material: this.state.material,
        blousepiece: this.state.blousepiece,
        fabricCare: this.state.fabriccare,
        filterColors: this.state.filterColors
      };

      //Image Upload
      let files = this.state.imgfiles;
      var formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i])
      }
      formData.append('newProd', JSON.stringify(newProd))

      //Add new Product
      addProduct(formData)
        .then((res) => {
          if (res.status === 200) {
            this.props.history.push('/products/physical/product-list');
            window.location.reload();
          }
          else {
            spinner.style.display = "none";
            alert("Something went wrong");
          }
        })
        .catch((err) => {
          alert('Something went wrong')
          spinner.style.display = "none";
        })
    };
  };

  ResetForm() {
    if (window.confirm('Reset Form?')) {
      window.location.reload();
    }
  }

  render() {
    const { products } = this.props;
    const { selectedView } = this.state

    return (
      <Fragment>
        <div id="spinneranimid" className="overlay-spinner">
          <div className="cssload-whirlpool" />
        </div>
        <Breadcrumb title="Add Product" parent="Shop" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Add Product</h5>
                </div>
                <div className="card-body">
                  <div className="row product-adding">
                    <div className="col-xl-5">
                      <div className="add-product">
                        <div className="row">
                          <div className="col-xl-9 xl-50 col-sm-6 col-9">
                            <img
                              src={this.state.reader}
                              alt=""
                              className="img-fluid image_zoom_1 blur-up lazyloaded"
                            />
                          </div>
                          <div className="col-xl-3 xl-50 col-sm-6 col-3">
                            <ul className="file-upload-product">
                              {
                                this.state.dummyimgs.map((res, i) => {
                                  return (
                                    <li key={i}>
                                      <div className="box-input-file">
                                        <input
                                          required
                                          className="upload"
                                          type="file"
                                          multiple
                                          onChange={(e) =>
                                            this._handleImgChange(e, i)
                                          }
                                          onSubmit={this.handleValidSubmit}
                                        />
                                        <img
                                          src={res.img}
                                          style={{ width: 50, height: 50 }}
                                        />
                                        <a
                                          id="result1"
                                          onClick={(e) =>
                                            this._handleSubmit(e.target.id)
                                          }
                                        ></a>
                                      </div>
                                    </li>
                                  );
                                })
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7" style={{ marginTop: 30 }}>
                      <form
                        onSubmit={this.handleValidSubmit}
                      >
                        <div className="form form-label-center">
                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              SKU :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                required
                                className="form-control"
                                name="sku"
                                id="sku"
                                placeholder="SKU CODE"
                                value={this.state.sku}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Product Name :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control"
                                name="name"
                                id="name"
                                type="text"
                                placeholder="Enter Product Name"
                                required
                                value={this.state.name}
                                onChange={this.onChange}
                              />
                            </div>
                            <div className="valid-feedback">Looks good!</div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Scratched Price :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control mb-0"
                                name="scratchedPrice"
                                id="scratchedPrice"
                                type="number"
                                required
                                placeholder="Scratched Price in ₹"
                                value={this.state.scratchedPrice}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Original Price :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control mb-0"
                                name="price"
                                id="price"
                                type="number"
                                required
                                placeholder="Original Price in ₹"
                                value={this.state.price}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>

                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Reseller Price :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control mb-0"
                                name="resellerprice"
                                id="resellerprice"
                                type="number"
                                required
                                placeholder="Reseller/Coupon price in ₹"
                                value={this.state.resellerprice}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Category :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <select
                                required
                                className="form-control mb-0"
                                name="category"
                                id="category"
                                value={this.state.category}
                                onChange={(e) => this.setState({ selectedView: e.target.value, [e.target.name]: e.target.value })}
                              >
                                {this.state.subcategories.map(({ name }, index2) =>
                                  <option key={index2} value={name}>{name}</option>
                                )}
                              </select>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Sub-category :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              {this.getSubcategory()}
                            </div>
                          </div>
                        </div>

                        <div className="form">
                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Stock(6 Yards) :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control mb-0"
                                name="stock6"
                                id="stock6"
                                type="number"
                                placeholder="Enter Stock (For size:6 Yards)"
                                required
                                value={this.state.stock6}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Stock(9 Yards) :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control mb-0"
                                name="stock9"
                                id="stock9"
                                type="number"
                                placeholder="Enter Stock (For size:9 Yards)"
                                required
                                value={this.state.stock9}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Filter Color :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <MultiSelect
                                options={this.state.colors}
                                value={this.state.filterColors}
                                labelledBy='Select Color'
                                onChange={(e) => this.setState({ filterColors: e })}
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Sold Out :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <select
                                required
                                className="form-control mb-0"
                                name="soldOut"
                                id="soldOut"
                                value={this.state.soldOut}
                                onChange={this.onChange}
                              >
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                              </select>
                            </div>
                          </div>

                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Weight :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control mb-0"
                                name="weight"
                                id="weight"
                                type="text"
                                placeholder="Enter Product weight"
                                required
                                value={this.state.weight}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Material :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control mb-0"
                                name="material"
                                id="material"
                                type="text"
                                placeholder="Enter Product material"
                                required
                                value={this.state.material}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>

                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Blouse Piece :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control mb-0"
                                name="blousepiece"
                                id="blousepiece"
                                type="text"
                                required
                                value={this.state.blousepiece}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>

                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Fabric Care :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control mb-0"
                                name="fabriccare"
                                id="fabriccare"
                                type="text"
                                required
                                value={this.state.fabriccare}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-xl-3 col-sm-4">
                              Full Description :
                            </label>
                            <div className="col-xl-8 col-sm-7 description-sm">
                              <div className="form-group">
                                <textarea rows="8" cols="36"
                                  name="content"
                                  id="content"
                                  type="text"
                                  required
                                  placeholder=""
                                  value={this.state.content}
                                  onChange={this.onChange}>
                                </textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="offset-xl-3 offset-sm-4">
                          <button type="submit" className="btn btn-primary">
                            Add Product
                          </button>
                          <button type="button" className="btn btn-light" onClick={this.ResetForm}>
                            Discard
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.data.products,
  symbol: state.data.symbol,
  categoryData: state.catsData.cats
})

export default connect(mapStateToProps)(AddProduct);
