import { Link } from 'react-router-dom';
import React, { Component, Fragment } from 'react'

import Breadcrumb from '../common/breadcrumb';
// import data from '../../assets/data/orders';
// import Datatable from '../common/datatable'

import { fetchUserData } from '../ProductFunctions';

export class UserList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userData: [],
        }
    }

    componentDidMount() {
        var checkusertoken = localStorage.getItem('usertokenSatyamAdmin');
        if (checkusertoken == null) {
            this.props.history.push(`/`)
        } else {
            fetchUserData()
                .then(res => {
                    if (res.status == 200) {
                        let usersList = []
                        usersList = res.data
                        this.setState({ userData: usersList.reverse() })
                    } else if (res.status === 401) {
                        this.setState({ userData: [] })
                    } else {
                        alert('Error Occured.. Please Try after some time!')
                    }
                })
                .catch((err) => {
                    alert('Something went wrong!')
                });
        }
    }

    render() {
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

        return (
            <Fragment>
                <Breadcrumb title="UserData" parent="Customers" />
                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Manage Users</h5>
                                </div>
                                <div className="card-body order-datatable lists-custom">
                                    {this.state.userData.length > 0 ?
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Registered On</th>
                                                    <th>Address</th>
                                                    {/* <th>Reseller Status</th> */}
                                                    <th>Email Confirmed</th>
                                                    <th>Phone No. Verified</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.userData.map((item, index) =>
                                                    <tr key={index}>

                                                        <td>{item.id}</td>
                                                        <td>{item.first_name}&nbsp;{item.last_name}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.phone}</td>
                                                        <td>{days[new Date(item.created).getDay()] + ', ' + monthNames[new Date(item.created).getMonth()] + ' ' + new Date(item.created).getDate() + ', ' + new Date(item.created).getFullYear()}</td>
                                                        <td>
                                                            <p>
                                                                {item.address},<br />
                                                                {item.city},&nbsp;{item.district} <br />
                                                                {item.state},&nbsp;{item.pincode}. <br />

                                                            </p>
                                                        </td>
                                                        {/* <td>{item.isReseller}</td> */}
                                                        <td>{item.confirmed}</td>
                                                        <td>{item.verifyPhoneNo}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        :
                                        <div>
                                            <p>No users registered yet.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default UserList
