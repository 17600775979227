import React, { Component, Fragment } from 'react'
import { withRouter } from "react-router-dom";
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';

import { register } from "../auth/Userfunctions";

export class Tabset_user extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeShow: true,
            startDate: new Date(),
        };
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onSubmit = (e) => {
        e.preventDefault();
        const user = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            username: this.state.username,
            password: this.state.password,
        };
        register(user).then((res) => {
            this.props.history.push(`${process.env.PUBLIC_URL}/dashboard`);
        });
    }
    render() {
        return (
            <Fragment>
                <Tabs>
                    <TabList className="nav nav-tabs tab-coupon" >
                        <Tab className="nav-link">Add Account</Tab>
                        <Tab className="nav-link">Permissions</Tab>
                    </TabList>
                    <TabPanel>
                        <form
                            className="needs-validation user-add"
                            noValidate=""
                            onSubmit={this.onSubmit}
                        >
                            <h4>Account Details</h4>
                            <div className="form-group row">
                                <label className="col-xl-3 col-md-4">
                                    <span>*</span> First Name</label>
                                <input className="form-control col-xl-8 col-md-7"
                                    required
                                    name="first_name"
                                    type="text"
                                    placeholder="First Name"
                                    id="first_name"
                                    value={this.state.first_name}
                                    onChange={this.onChange} />
                            </div>
                            <div className="form-group row">
                                <label className="col-xl-3 col-md-4"><span>*</span> Last Name</label>
                                <input className="form-control col-xl-8 col-md-7" required
                                    name="last_name"
                                    type="text"
                                    placeholder="Last Name"
                                    id="last_name"
                                    value={this.state.last_name}
                                    onChange={this.onChange} />
                            </div>
                            <div className="form-group row">
                                <label className="col-xl-3 col-md-4"><span>*</span> Email</label>
                                <input className="form-control col-xl-8 col-md-7" required
                                    name="email"
                                    type="email"
                                    placeholder="Email"
                                    id="exampleInputEmail1"
                                    value={this.state.email}
                                    onChange={this.onChange} />
                            </div>
                            <div className="form-group row">
                                <label className="col-xl-3 col-md-4"><span>*</span> Username</label>
                                <input className="form-control col-xl-8 col-md-7"
                                    required
                                    name="username"
                                    type="text"
                                    placeholder="Username"
                                    value={this.state.username}
                                    onChange={this.onChange} />
                            </div>
                            <div className="form-group row">
                                <label className="col-xl-3 col-md-4"><span>*</span> Password</label>
                                <input className="form-control col-xl-8 col-md-7" required
                                    id="passwordid"
                                    name="password"
                                    type="password"
                                    placeholder="Password"
                                    value={this.state.password}
                                    onChange={this.onChange} />
                            </div>
                            <div className="pull-right">
                                <button
                                    type="submit"
                                    className="btn btn-primary">
                                    Add User
                                </button>
                            </div>
                        </form>
                    </TabPanel>
                    <TabPanel>
                        <form className="needs-validation user-add" noValidate="">
                            <div className="permission-block">
                                <div className="attribute-blocks">
                                    <h5 className="f-w-600 mb-3">Product Related Permission </h5>
                                    <div className="row">
                                        <div className="col-xl-3 col-sm-4">
                                            <label>Add Product</label>
                                        </div>
                                        <div className="col-xl-9 col-sm-8">
                                            <div className="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                <label className="d-block">
                                                    <input className="radio_animated" id="edo-ani1" type="radio" name="rdo-ani" />
                                                    Allow
                                                    </label>
                                                <label className="d-block" >
                                                    <input className="radio_animated" id="edo-ani2" type="radio" name="rdo-ani" defaultChecked />
                                                    Deny
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-3 col-sm-4">
                                            <label>Update Product</label>
                                        </div>
                                        <div className="col-xl-9 col-sm-8">
                                            <div className="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                <label className="d-block" >
                                                    <input className="radio_animated" id="edo-ani3" type="radio" name="rdo-ani1" defaultChecked />
                                                    Allow
                                                                </label>
                                                <label className="d-block">
                                                    <input className="radio_animated" id="edo-ani4" type="radio" name="rdo-ani1" />
                                                    Deny
                                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-3 col-sm-4">
                                            <label>Delete Product</label>
                                        </div>
                                        <div className="col-xl-9 col-sm-8">
                                            <div className="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                <label className="d-block" >
                                                    <input className="radio_animated" id="edo-ani5" type="radio" name="rdo-ani2" />
                                                    Allow
                                                                </label>
                                                <label className="d-block" >
                                                    <input className="radio_animated" id="edo-ani6" type="radio" name="rdo-ani2" defaultChecked />
                                                    Deny
                                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-3 col-sm-4">
                                            <label className="mb-0 sm-label-radio">Apply Discount</label>
                                        </div>
                                        <div className="col-xl-9 col-sm-8">
                                            <div className="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated pb-0">
                                                <label className="d-block mb-0" >
                                                    <input className="radio_animated" id="edo-ani7" type="radio" name="rdo-ani3" />
                                                    Allow
                                                                </label>
                                                <label className="d-block mb-0" >
                                                    <input className="radio_animated" id="edo-ani8" type="radio" name="rdo-ani3" defaultChecked />
                                                    Deny
                                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="attribute-blocks">
                                    <h5 className="f-w-600 mb-3">Category Related Permission </h5>
                                    <div className="row">
                                        <div className="col-xl-3 col-sm-4">
                                            <label>Add Category</label>
                                        </div>
                                        <div className="col-xl-9 col-sm-8">
                                            <div className="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                <label className="d-block" >
                                                    <input className="radio_animated" id="edo-ani9" type="radio" name="rdo-ani4" />
                                                    Allow
                                                                </label>
                                                <label className="d-block" >
                                                    <input className="radio_animated" id="edo-ani10" type="radio" name="rdo-ani4" defaultChecked />
                                                    Deny
                                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-3 col-sm-4">
                                            <label>Update Category</label>
                                        </div>
                                        <div className="col-xl-9 col-sm-8">
                                            <div className="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                <label className="d-block" >
                                                    <input className="radio_animated" id="edo-ani11" type="radio" name="rdo-ani5" />
                                                    Allow
                                                                </label>
                                                <label className="d-block" >
                                                    <input className="radio_animated" id="edo-ani12" type="radio" name="rdo-ani5" defaultChecked />
                                                    Deny
                                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-3 col-sm-4">
                                            <label>Delete Category</label>
                                        </div>
                                        <div className="col-xl-9 col-sm-8">
                                            <div className="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                <label className="d-block" >
                                                    <input className="radio_animated" id="edo-ani13" type="radio" name="rdo-ani6" />
                                                    Allow
                                                                </label>
                                                <label className="d-block" >
                                                    <input className="radio_animated" id="edo-ani14" type="radio" name="rdo-ani6" defaultChecked />
                                                    Deny
                                                    </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-3 col-sm-4">
                                            <label className="mb-0 sm-label-radio">Apply Discount</label>
                                        </div>
                                        <div className="col-xl-9 col-sm-8">
                                            <div className="form-group m-checkbox-inline custom-radio-ml d-flex radio-animated pb-0">
                                                <label className="d-block mb-0" >
                                                    <input className="radio_animated" id="edo-ani15" type="radio" name="rdo-ani7" />
                                                    Allow
                                                                </label>
                                                <label className="d-block mb-0" >
                                                    <input className="radio_animated" id="edo-ani16" type="radio" name="rdo-ani7" defaultChecked />
                                                    Deny
                                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </TabPanel>
                </Tabs>

            </Fragment>
        )
    }
}

export default withRouter(Tabset_user);
