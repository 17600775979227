import React, { Component, Fragment } from 'react'
import LoginTabset from './loginTabset';
import { Home } from 'react-feather';
// import Slider from 'react-slick';

import logomonogram from '../../assets/images/logo/logomonogram.png';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export class Login extends Component {
    componentDidMount() {
        console.log(process.env.PUBLIC_URL)
        var checkusertoken = localStorage.getItem('usertokenSatyamAdmin');
        if (checkusertoken !== null) {
            this.props.history.push(`/dashboard`)
        }
    }
    render() {
        // var settings = {
        //     dots: true,
        //     infinite: true,
        //     speed: 500,
        //     arrows: false
        // };
        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="authentication-box">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5 p-0 card-left">
                                    <div className="card bg-primary">
                                        <div className="svg-icon">
                                            <img src={logomonogram} alt="" className="Img-fluid" />
                                        </div>
                                        {/* <Slider className="single-item" {...settings}> */}
                                        <div>
                                            <div>
                                                <h3>Satyam Paithani</h3>
                                                <p>Leading manufacturer and supplier of the variety of Indian traditional wear.
                                                    We provide a wide artistic range of Paithani.</p>
                                            </div>
                                        </div>
                                        {/* <div>
                                                <div>
                                                    <h3>Welcome to Murtikaar</h3>
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <h3>Welcome to Murtikaar</h3>
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.</p>
                                                </div>
                                            </div>
                                        </Slider > */}
                                    </div>
                                </div>
                                <div className="col-md-7 p-0 card-right">
                                    <div className="card tab2-card">
                                        <div className="card-body">
                                            <LoginTabset />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a href={process.env.REACT_APP_BASE_URL} target="_blank" rel="noopener noreferrer" className="btn btn-primary back-btn"><Home />Home</a>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Login
