import axios from 'axios';

export const fetchLinks = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/fetchlinks`, {})
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const addLink = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/addlink`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const deleteLink = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/deletelink`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchNetworks = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/fetchnetworks`, {})
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const addNetwork = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/addnetwork`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const deleteNetwork = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/deletenetwork`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchSpecialTxns = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/fetchspecialtxns`, {})
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const addSpecialTxn = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/addspecialtxn`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchIntroSliders = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/fetchintrosliders`, {})
        .then(res => { return res.data; })
        .catch(err => { return err.response; })
};

export const updateIntroSliderImage = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/updateintrosliderimage`, data)
        .then(res => { return res.data; })
        .catch(err => { return err.response; })
};

export const fetchClientTestimonials = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/fetchclienttestimonials`, {})
        .then(res => { return res.data; })
        .catch(err => { return err.response; })
};

export const addClientTestimonial = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/addclienttestimonial`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const deleteClientTestimonial = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/deleteclienttestimonial`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchNotifications = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/fetchnotifications`, {})
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const pushNotification = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/pushnotification`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const deleteNotification = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/deletenotification`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchTeamMembers = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/fetchteammembers`, {})
        .then(res => { return res.data; })
        .catch(err => { return err.response; })
};

export const addTeamMember = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/addteammember`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const deleteTeamMember = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/deleteteammember`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const changeSequence = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/changesequence`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};