import axios from "axios";

//fetch single order
export const fetchSingleOrderAdmin = async (orders) => {
  return axios
    .post(process.env.PUBLIC_URL + "users/fetchSingleOrder", {
      orderid: orders.orderid
    })
    .then((res) => {
      localStorage.setItem("singleOrderShivshahiAdmin", JSON.stringify(res.data));
      return res;
    })
    .catch(err => {
      return err.response;
    });
};

//fetch all orders
export const fetchOrdersAdmin = async (orders) => {
  return axios
    .post(process.env.PUBLIC_URL + "users/fetchordersadmin", {})
    .then((res) => {
      // localStorage.setItem("allOrdersShivshahiAdmin", JSON.stringify(res.data));
      return res;
    })
    .catch(err => {
      return err.response;
    });
};

//change orderstatus
export const changeOrderStatus = async (orders) => {
  return axios
    .post(process.env.PUBLIC_URL + "users/changeorderstatus", {
      id: orders.id,
      orderid: orders.orderid,
      status: orders.status,
      email: orders.email,
      phone: orders.phone,
      payment_mode: orders.payment_mode,
      marginCall: orders.marginCall,
      trackDetails: orders.trackDetails,
      fullfilledBy: orders.fullfilledBy
    })
    .then((res) => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
};

//Add resellers
export const fetchResellers = async (orders) => {
  return axios
    .post(process.env.PUBLIC_URL + "/users/fetch/reseller-list", {

    })
    .then((res) => {
      localStorage.setItem("resellerlist", JSON.stringify(res.data));
      return res;
    })
    .catch(err => {
      return err.response;
    });
};

//fetch resellers
export const makeResellers = async (user) => {
  return axios
    .post(process.env.PUBLIC_URL + "/users/makereseller", {
      email: user.email
    })
    .then((res) => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
};

//Add resellers
export const fetchCoponCodes = async (orders) => {
  return axios
    .post(process.env.PUBLIC_URL + "/users/fetch/coupon-codes", {

    })
    .then((res) => {
      // localStorage.setItem("resellerlist", JSON.stringify(res.data));
      return res;
    })
    .catch(err => {
      return err.response;
    });
};

//fetch resellers
export const generateCouponCode = async (codedata) => {
  return axios
    .post(process.env.PUBLIC_URL + "/users/generate-coupon-code", { codedata })
    .then((res) => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
};

//fetch all users
export const fetchUserData = async (orders) => {
  return axios
    .post(process.env.PUBLIC_URL + "users/fetchUserData", {})
    .then((res) => {
      //localStorage.setItem("allOrdersShivshahiAdmin", JSON.stringify(res.data));
      return res;
    })
    .catch(err => {
      return err.response;
    });
};