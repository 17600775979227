/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from "react";
import { MultiSelect } from 'react-multi-select-component';
import Breadcrumb from "../../common/breadcrumb";
// import CKEditors from "react-ckeditor-component";
// import { AvField, AvForm } from "availity-reactstrap-validation";
// import one from "../../../assets/images/pro3/1.jpg";
// import user from "../../../assets/images/user.png";
// import { updateProduct } from "../physical/product-functions/product-functions";
import { updateProduct } from "./../../../api/product";
import { fetchColor, fetchPrdFilterColor } from "../physical/product-functions/product-functions";
import axios from "axios";
import { connect } from "react-redux";

export class EditProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.item.id,
      sku: this.props.item.sku,
      name: this.props.item.name,
      soldOut: this.props.item.soldout,
      scratchedPrice: this.props.item.scratchedPrice,
      price: this.props.item.price,
      resellerPrice: this.props.item.resellerPrice,
      resellerDiscount: this.props.item.resellerdiscount,
      selectedView: '-Select Category-',
      category: this.props.item.category[0],
      subcat: this.props.item.subcategory[0],
      // rating: this.props.item.rating,
      // available: this.props.available,
      // preorder: this.props.preorder,
      content: this.props.item.fulldescription,
      // color: this.props.item.variation[0].color,
      stock6: this.props.item.variation[0].size[0].stock,
      stock9: this.props.item.variation[0].size[0].stock,
      // stock11: this.props.item.variation[0].size[0].stock,
      weight: this.props.item.weight,
      material: this.props.item.material,
      blousepiece: this.props.item.blousepiece,
      fabricCare: this.props.item.fabricCare,
      //dimensions: this.props.item.Dimensions,
      //otherinf: this.props.item.OtherInfo,
      file: [
      ],
      image: [
        {},
        {},
        {},
        {},
        {},
        {}
      ],
      imgfiles: [
        {},
        {},
        {},
        {},
        {},
        {}
      ],
      filenames: [
        this.props.item.image[0],
        this.props.item.image[1],
        this.props.item.image[2],
        this.props.item.image[3],
        this.props.item.image[4],
        this.props.item.image[5]
      ],
      variants: [
        {},
        {},
        {},
        {},
        {},
        {}
      ],
      reader: [`${process.env.REACT_APP_BASE_URL}/${this.props.item.image[0]}`],
      dummyimgs: [
        { img: `${process.env.REACT_APP_BASE_URL}/${this.props.item.image[0]}` },
        { img: `${process.env.REACT_APP_BASE_URL}/${this.props.item.image[1]}` },
        { img: `${process.env.REACT_APP_BASE_URL}/${this.props.item.image[2]}` },
        { img: `${process.env.REACT_APP_BASE_URL}/${this.props.item.image[3]}` },
        { img: `${process.env.REACT_APP_BASE_URL}/${this.props.item.image[4]}` },
        { img: `${process.env.REACT_APP_BASE_URL}/${this.props.item.image[5]}` }
      ],
      variation: [
        {

        }
      ],
      categories: [],
      subcategories: [],
      colors: [],
      filterColors: []
    };
  }

  componentDidMount = async () => {
    var checkusertoken = localStorage.getItem('usertokenSatyamAdmin');
    if (checkusertoken === null) {
      this.props.history.push(`/`)
    } else {
      var subcategoriestest = [
        { value: ["-Select Category-"], name: '-Select Category-' }
      ]

      await fetchColor()
        .then(res => {
          if (res.status == 200) {
            var colors = res.data.data
            var colorsForMultiSelect = []
            for (var i = 0; i < colors.length; i++) {
              var color = colors[i]
              colorsForMultiSelect.push({ label: color.color, value: color.color })
            }
            this.setState({ colors: colorsForMultiSelect })
          } else {
            alert('Error occured.. Please try after some time')
          }
        })
        .catch((err) => {
          alert(err)
        })

      await fetchPrdFilterColor({ id: this.state.id })
        .then((response) => {
          if (response.data.status = 200 && response.data.data) {
            var filterColors = response.data.data
            var finalFilterColour = [];
            for (var i = 0; i < filterColors.length; i++) {
              finalFilterColour.push({ label: filterColors[i].ColorColor, value: filterColors[i].ColorColor })
            }
            this.setState({
              filterColors: finalFilterColour
            })
          }
        })
        .catch(error => {

        })


      let cats = this.props.categoryData;

      var catArr = []
      for (var i = 0; i < cats.length; i++) {
        catArr.push(cats[i].category)
      }

      let uniqueCats = [...new Set(catArr)];

      var ArrFinal = []

      for (var j = 0; j < uniqueCats.length; j++) {

        var subcat = cats.filter((hero) =>
          hero.category == uniqueCats[j]
        )

        var subcatArrFinal = []
        for (var k = 0; k < subcat.length; k++) {
          subcatArrFinal.push(subcat[k].subcategory)
        }

        ArrFinal.push({
          id: j + 1,
          cat: uniqueCats[j],
          subcats: subcatArrFinal
        })

        var subcategories = []
        var categories = []

        for (var i = 0; i < ArrFinal.length; i++) {
          categories.push(ArrFinal[i].cat);
          subcategories.push(ArrFinal[i].subcats);
        }


      }

      for (let i = 0, j = 0; i < subcategories.length, j < categories.length; i++, j++) {
        let initvalue = ["-Select Sub-Category-"]

        let sub = subcategories[i]

        const finalvalue = initvalue.concat(sub)

        let subcatobject = {
          value: finalvalue, name: categories[j]
        }

        subcategoriestest.push(subcatobject)

      }

      this.setState({
        categories: categories,
        subcategories: subcategoriestest
      })
    }

  }

  updateContent(newContent) {
    this.setState({
      content: newContent
    })
  }

  onBlur(evt) {

  }

  afterPaste(evt) {

  }

  ontxtChange = (evt) => {
    var newContent = evt.editor.getData();
    this.setState({
      content: newContent
    })
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    if (e.target.name == 'subcat') {
      this.setState({
        subcategory: e.target.value
      });
    }

    if (e.target.name == 'soldOut' && e.target.value == 'true') {
      this.setState({
        stock6: '0',
        stock9: '0'
      });
    }

    if (e.target.name == 'stock6' || e.target.name == 'stock9') {
      if (e.target.value !== '0') {
        this.setState({
          soldOut: 'false'
        });
      }
    }
  }

  _handleImgChange = (e, i) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    const { dummyimgs } = this.state;
    const { filenames } = this.state;
    const { imgfiles } = this.state;
    const { variants } = this.state
    const path = '/assets/img/product/fashion/';

    reader.onloadend = () => {
      dummyimgs[i].img = reader.result;
      filenames[i] = path + file.name;

      imgfiles[i] = file
      this.setState({
        file: file,
        filenames,
        dummyimgs,
        imgfiles,
        reader: reader.result,
        variants
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  handleValidSubmit = async (e) => {
    e.preventDefault();

    let spinner = document.getElementById("spinneranimid");

    if (window.confirm('Update Product?')) {
      spinner.style.display = "block";

      var array1 = this.state.filenames
      //var array2 = array2.filter(value => Object.keys(value).length !==  undefined);
      var imageFiles = array1.filter(Boolean);

      // var array2 = this.state.variants
      // var imgvariants = array2.filter(value => Object.keys(value).length !== 0);

      function discount(value1, value2) {
        return (value2 / value1) * 100
      }

      if (this.state.finalPrice) {

        var defDiscount = await discount(this.state.price, this.state.finalPrice)
        var resellerDiscount = await discount(this.state.finalPrice, this.state.resellerPrice)

        this.setState({
          discount: defDiscount,
          resellerDiscount: resellerDiscount
        })
      }

      // const updateProd = {
      //   id: this.state.id,
      //   sku: this.state.sku,
      //   name: this.state.name,
      //   price: this.state.price,
      //   finalPrice: this.state.finalPrice,
      //   discount: this.state.discount,
      //   resellerPrice: this.state.resellerprice,
      //   resellerDiscount: this.state.resellerDiscount,
      //   // color: this.state.color,
      //   category: this.state.category,
      //   subcategory: this.state.subcat,
      //   // rating: this.state.rating,
      //   stock6: this.state.stock6,
      //   stock9: this.state.stock9,
      //   soldout: this.state.soldOut,
      //   // stock11: this.state.stock11,
      //   weight: this.state.weight,
      //   material: this.state.material,
      //   blousepiece: this.state.blousepiece,
      //   fabriccare: this.state.fabriccare,
      //   //dimensions: this.state.dimensions,
      //   //otherinf: this.state.otherinf,
      //   // new: this.state.new,
      //   content: this.state.content,
      //   imageFiles,
      // };

      const newProd = {
        id: this.state.id,
        sku: this.state.sku,
        name: this.state.name,
        price: this.state.price,
        scratchedPrice: this.state.scratchedPrice,
        // finalprice: this.state.finalPrice,
        // discount: this.state.discount,
        resellerprice: this.state.resellerPrice,
        resellerdiscount: this.state.resellerDiscount,
        category: [
          this.state.category
        ],
        subcategory: [
          this.state.subcat
        ],
        variation: [
          {
            color: "white",
            size: [
              {
                name: "6 yards",
                stock: this.state.stock6
              },
              {
                name: "9 yards",
                stock: this.state.stock9
              }
            ]
          }
        ],
        soldout: this.state.soldOut,
        image: imageFiles,
        fulldescription: this.state.content,
        weight: this.state.weight,
        material: this.state.material,
        blousepiece: this.state.blousepiece,
        fabricCare: this.state.fabricCare,
        filterColors: this.state.filterColors
      };

      //Image Upload
      let files = this.state.imgfiles;

      var formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i])
      }

      formData.append('newProd', JSON.stringify(newProd))

      //Product Images and Details update
      // axios.post('/users/upload', formData)
      //   .then((res) => {
      // Post Jason
      updateProduct(formData)
        .then((res) => {
          if (res.status === 200) {
            this.props.history.push(`/products/physical/product-detail/${this.state.id}`);
            window.location.reload();
          } else {
            spinner.style.display = "none";
            alert("Something went wrong");
          }
        })
        .catch((error) => {
          spinner.style.display = "none";
          alert("Something went wrong");
        });
      // })
      // .catch((error) => {
      //   spinner.style.display = "none";
      //   alert("Something went wrong");
      // });
    } else {
      spinner.style.display = "none";
    }
  };

  ResetForm() {
    // window.confirm('Reset Form?');
    if (window.confirm('Reset Form?')) {
      window.location.reload();
    }
  }

  getSubcategory = () => {
    try {
      let subcategories = this.state.subcategories
      const view = subcategories.filter(({ name }) => name === this.state.category)[0]

      return (
        <div>
          <select
            required
            className="form-control mb-0"
            name="subcat"
            id="subcat"
            value={this.state.subcat}
            onChange={this.onChange}
          >
            {view.value.map((m, idx) => <option key={idx} value={m}>{m}</option>)}
          </select>
        </div>
      )

    }
    catch (err) {
    }

  }

  render() {
    const { selectedView } = this.state
    // const subcategories = [
    //   { value: [this.state.subcategory], name: '-Select Category-' },
    //   { value: ["-Select Sub-Category-", "Cotton Paithani", "Brocket Paithani", "Yeola Paithani", "Designer Paithani", "Blouse Piece", "Dupatta"], name: 'Handlooms' },
    //   { value: ["-Select Sub-Category-", "Pure Silk Sarees", "Semi Silk Sarees", "Kanchipuram Silk Sarees", "Banarasi Silk Sarees", "Cotton Silk Sarees", "Fancy Silk Sarees", "Designer Sarees"], name: 'Silk Sarees' },
    //   { value: ["-Select Sub-Category-", "Pure Silk Dupattas", "Semi Silk Dupattas", "Cotton Silk Dupattas"], name: 'Dupattas' },
    //   { value: ["-Select Sub-Category-", "Pure Silk Nauvari", "Semi Silk Nauvari", "Cotton Silk Nauvari"], name: 'Nauvari (9 Yards)' }
    // ];

    // const getSubcategory = () => {
    //   const view = subcategories.filter(({ name }) => name === selectedView)[0]
    //   return (
    //     <div>
    //       <select
    //         required
    //         className="form-control mb-0"
    //         name="subcategory"
    //         id="subcategory"
    //         value={this.state.subcategory}
    //         onChange={this.onChange}
    //       >
    //         {view.value.map(m => <option value={m}>{m}</option>)}
    //       </select>
    //     </div>
    //   )
    // }
    return (
      <Fragment>
        <div id="spinneranimid" className="overlay-spinner">
          <div className="cssload-whirlpool" />
        </div>
        <Breadcrumb title="Update Product" parent="Shop" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Update Product <span>(Category: {this.props.item.category[0]} & Subcategory: {this.props.item.subcategory[0]})</span></h5>
                </div>
                <div className="card-body">
                  <div className="row product-adding">
                    <div className="col-xl-5">
                      <div className="add-product">
                        <div className="row">
                          <div className="col-xl-9 xl-50 col-sm-6 col-9">
                            <img
                              src={this.state.reader}
                              alt=""
                              className="img-fluid image_zoom_1 blur-up lazyloaded"
                            />
                          </div>
                          <div className="col-xl-3 xl-50 col-sm-6 col-3">
                            <ul className="file-upload-product">
                              {this.state.dummyimgs.map((res, i) => {
                                return (
                                  <li key={i}>
                                    <div className="box-input-file">
                                      <input
                                        required
                                        className="upload"
                                        type="file"
                                        multiple
                                        onChange={(e) =>
                                          this._handleImgChange(e, i)
                                        }
                                        onSubmit={this.handleValidSubmit}
                                      />
                                      <img
                                        src={res.img}
                                        style={{ width: 50, height: 50 }}
                                      />
                                      <a
                                        id="result1"
                                        onClick={(e) =>
                                          this._handleSubmit(e.target.id)
                                        }
                                      ></a>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7" style={{ marginTop: 30 }}>
                      <form
                        // className="needs-validation add-product-form"
                        onSubmit={this.handleValidSubmit}
                      // onInvalidSubmit={this.handleInvalidSubmit}
                      >
                        <div className="form form-label-center">
                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              SKU :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                required
                                className="form-control"
                                name="sku"
                                id="sku"
                                placeholder="SKU CODE"
                                value={this.state.sku}
                                onChange={this.onChange}
                              />
                            </div>
                            {/* <div className="valid-feedback">Looks good!</div> */}
                          </div>
                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Product Name :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control"
                                name="name"
                                id="name"
                                type="text"
                                placeholder="Enter Product Name"
                                required
                                value={this.state.name}
                                onChange={this.onChange}
                              />
                            </div>
                            {/* <div className="valid-feedback">Looks good!</div> */}
                          </div>
                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Scratched Price :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control mb-0"
                                name="scratchedPrice"
                                id="scratchedPrice"
                                type="number"
                                required
                                placeholder="Scratched Price in ₹"
                                value={this.state.scratchedPrice}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Original Price :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control mb-0"
                                name="price"
                                id="price"
                                type="number"
                                required
                                placeholder="Actual Price in ₹"
                                value={this.state.price}
                                onChange={this.onChange}
                              />
                            </div>
                            {/* <div className="valid-feedback">Looks good!</div> */}
                          </div>
                          {/* <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Final Price :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control mb-0"
                                name="finalPrice"
                                id="finalPrice"
                                type="number"
                                required
                                placeholder="Actual Price in ₹"
                                value={this.state.finalPrice}
                                onChange={this.onChange}
                              />
                            </div>
                          </div> */}
                          {/* <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Discount :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control mb-0"
                                name="discount"
                                id="discount"
                                type="number"
                                required
                                placeholder="Discount in %"
                                value={this.state.discount}
                                onChange={this.onChange}
                              />
                            </div>
                          </div> */}
                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Reseller Price :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control mb-0"
                                name="resellerPrice"
                                id="resellerPrice"
                                type="number"
                                required
                                placeholder="Actual Price in ₹"
                                value={this.state.resellerPrice}
                                onChange={this.onChange}
                              />
                            </div>

                          </div>


                          <div className="form-group row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Category :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <select
                                required
                                className="form-control mb-0"
                                name="category"
                                id="category"
                                value={this.state.category}
                                onChange={(e) => this.setState({ selectedView: e.target.value, [e.target.name]: e.target.value })}
                              >
                                {this.state.subcategories.map(({ name }, idx) => {
                                  return <option key={idx} value={name}>{name}</option>;
                                })}
                              </select>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Sub-category :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              {this.getSubcategory()}
                            </div>
                          </div>

                          {/* <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Rating :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control mb-0"
                                name="rating"
                                id="rating"
                                type="number"
                                placeholder="Enter Rating (On a scale of 1-5)"
                                required
                                value={this.state.rating}
                                onChange={this.onChange}
                              />
                            </div>
                          </div> */}

                        </div>
                        <div className="form">

                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Stock(6 Yards) :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control mb-0"
                                name="stock6"
                                id="stock6"
                                type="number"
                                placeholder="Enter Stock (For size:6 Yards)"
                                required
                                value={this.state.stock6}
                                onChange={this.onChange}
                              />
                            </div>
                            {/* <div className="valid-feedback">Looks good!</div> */}
                          </div>
                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Stock(9 Yards) :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control mb-0"
                                name="stock9"
                                id="stock9"
                                type="number"
                                placeholder="Enter Stock (For size:9 Yards)"
                                required
                                value={this.state.stock9}
                                onChange={this.onChange}
                              />
                            </div>
                            {/* <div className="valid-feedback">Looks good!</div> */}
                          </div>

                          <div className="form-group row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Filter Color :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <MultiSelect
                                options={this.state.colors}
                                value={this.state.filterColors}
                                labelledBy='Select Color'
                                onChange={(e) => this.setState({ filterColors: e })}
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Sold Out :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <select
                                required
                                className="form-control mb-0"
                                name="soldOut"
                                id="soldOut"
                                value={this.state.soldOut}
                                onChange={this.onChange}
                              >
                                <option value="false">Select Status</option>
                                <option value="true">Sold Out</option>
                              </select>
                            </div>
                          </div>

                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Weight :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control mb-0"
                                name="weight"
                                id="weight"
                                type="text"
                                placeholder="Enter Product weight"
                                required
                                value={this.state.weight}
                                onChange={this.onChange}
                              />
                            </div>
                            {/* <div className="valid-feedback">Looks good!</div> */}
                          </div>

                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Material :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control mb-0"
                                name="material"
                                id="material"
                                type="text"
                                //placeholder="Enter Product material"
                                required
                                value={this.state.material}
                                onChange={this.onChange}
                              />
                            </div>
                            {/* <div className="valid-feedback">Looks good!</div> */}
                          </div>

                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Blouse Piece :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control mb-0"
                                name="blousepiece"
                                id="blousepiece"
                                type="text"
                                //placeholder="Enter Product material"
                                required
                                value={this.state.blousepiece}
                                onChange={this.onChange}
                              />
                            </div>
                            {/* <div className="valid-feedback">Looks good!</div> */}
                          </div>

                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Fabric Care :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control mb-0"
                                name="fabricCare"
                                id="fabricCare"
                                type="text"
                                //placeholder="Enter Product material"
                                required
                                value={this.state.fabricCare}
                                onChange={this.onChange}
                              />
                            </div>
                            {/* <div className="valid-feedback">Looks good!</div> */}
                          </div>

                          <div className="form-group row">
                            <label className="col-xl-3 col-sm-4">
                              Full Description :
                            </label>
                            <div className="col-xl-8 col-sm-7 description-sm">
                              <div className="form-group">
                                <textarea rows="8" cols="36"
                                  name="content"
                                  id="content"
                                  type="text"
                                  required
                                  placeholder=""
                                  value={this.state.content}
                                  onChange={this.onChange}>
                                </textarea>
                              </div>
                            </div>
                          </div>
                          {/* <div className="form-group row">
                            <label className="col-xl-3 col-sm-4">
                              Other Info :
                            </label>
                            <div className="col-xl-8 col-sm-7 description-sm">
                              <div className="form-group">
                                <textarea rows="8" cols="36"
                                  name="otherinf"
                                  id="otherinf"
                                  type="text"
                                  required
                                  placeholder=""
                                  value={this.state.otherinf}
                                  onChange={this.onChange}>
                                </textarea>
                              </div>
                            </div>
                          </div>*/}
                        </div>
                        {/* <div className="form-group row">
                          <label className="col-xl-3 col-sm-4">
                            Full Description :
                            </label>
                          <div className="col-xl-8 col-sm-7 description-sm">
                            <CKEditors
                              activeclassName="p10"
                              content={this.state.content}
                              events={{
                                "blur": this.onBlur,
                                "afterPaste": this.afterPaste,
                                "change": this.ontxtChange
                              }}
                            />
                          </div>
                        </div> */}
                        <div className="offset-xl-3 offset-sm-4">
                          <button type="submit" className="btn btn-primary">
                            Update
                          </button>
                          <button type="button" className="btn btn-light" onClick={this.ResetForm}>
                            Discard
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let productId = ownProps.match.params.id;
  return {
    item: state.data.products.find(el => el.id == productId),
    symbol: state.data.symbol,
    categoryData: state.catsData.cats
  }
}

export default connect(mapStateToProps)(EditProduct);
