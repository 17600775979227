import React, { Component, Fragment } from 'react'
import Breadcrumb from '../../common/breadcrumb';
import 'react-toastify/dist/ReactToastify.css';
import { addPriceTag, updatePriceTag, deletePriceTag, fetchPriceTag } from "../physical/product-functions/product-functions";

export class PriceTags extends Component {
    constructor(props) {
        super(props);
        this.state = {
            priceTagData: [],
            addorupdate: 'Add Price Tag',
            newLowPriceTag: '',
            newHighPriceTag: '',
            priceTagToUpdate: '',
            updatedLowPriceTag: '',
            updatedHighPriceTag: ''
        };
    }

    componentDidMount() {
        var checkusertoken = localStorage.getItem('usertokenSatyamAdmin');
        if (checkusertoken == null) {
            this.props.history.push(`/`)
        } else {
            fetchPriceTag()
                .then(res => {
                    if (res.status == 200) {
                        this.setState({
                            priceTagData: res.data.data,
                            priceTagToUpdate: res.data.data[0] ? res.data.data[0].id : '',
                            updatedLowPriceTag: res.data.data[0] ? res.data.data[0].lowPrice : '',
                            updatedHighPriceTag: res.data.data[0] ? res.data.data[0].highPrice : ''
                        })
                    } else {
                        alert('Error occured.. Please try after some time')
                    }
                })
                .catch((err) => {
                    alert(err)
                })
        }
    }

    addPriceTagFn = (e) => {
        e.preventDefault();
        if (window.confirm('Add Price Tag?')) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            const data = {
                lowPrice: this.state.newLowPriceTag,
                highPrice: this.state.newHighPriceTag,
            }
            addPriceTag(data)
                .then(res => {
                    if (res.data.status == 200) {
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.data.status == 400) {
                        alert(res.data.msg)
                        spinner.style.display = "none";
                    } else {
                        alert('Error occured while adding price tag... Please try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error occured while adding price tag... Please try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    updatePriceTagFn = (e) => {
        e.preventDefault();
        if (window.confirm('Update Price Tag?')) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            const data = {
                id: this.state.priceTagToUpdate,
                lowPrice: this.state.updatedLowPriceTag,
                highPrice: this.state.updatedHighPriceTag,
            }
            updatePriceTag(data)
                .then(res => {
                    if (res.data.status == 200) {
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.data.status == 400) {
                        alert(res.data.msg)
                        spinner.style.display = "none";
                    } else {
                        alert('Error occured while updating price tag... Please try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error occured while updating price tag... Please try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    deletePriceTagFn = (e, id) => {
        e.preventDefault();
        if (window.confirm('Delete Price Tag?')) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            const data = { id: id }
            deletePriceTag(data)
                .then(res => {
                    if (res.data.status == 200) {
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.data.status == 400) {
                        alert(res.data.msg)
                        spinner.style.display = "none";
                    } else {
                        alert('Error occured while deleting price tag.. Please try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error occured while deleting price tag.. Please try after some time1')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Price Tags" parent="Shop" />
                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Add or Update Price Tag</h5>
                                </div>
                                <div className="card-header">
                                    <select className="form-control" id="addorupdate" name="addorupdate" value={this.state.addorupdate} onChange={(e) => this.setState({ addorupdate: e.target.value })} style={{ maxWidth: '250px' }}>
                                        <option value="Add Price Tag">Add Price Tag</option>
                                        <option value="Update Price Tag">Update Price Tag</option>
                                    </select>
                                    <hr />
                                    <div style={{ display: this.state.addorupdate == 'Add Price Tag' ? '' : 'none' }}>
                                        <h5>Add Price Tag</h5>
                                        <form onSubmit={this.addPriceTagFn}>
                                            <label className='mt-3'>Low Price : </label>
                                            <input style={{ maxWidth: '350px' }} className="form-control" id="lowPrice" name="lowPrice" onChange={(e) => this.setState({ newLowPriceTag: e.target.value })} required />
                                            <label className='mt-3'>High Price : </label>
                                            <input style={{ maxWidth: '350px' }} className="form-control" id="highPrice" name="highPrice" onChange={(e) => this.setState({ newHighPriceTag: e.target.value })} required />
                                            <button type="submit" className="btn btn-primary mt-3">
                                                Add
                                            </button>
                                        </form>
                                    </div>

                                    <div style={{ display: this.state.addorupdate == 'Update Price Tag' ? '' : 'none' }}>
                                        <h5>Update Price Tag</h5>
                                        <form onSubmit={this.updatePriceTagFn}>
                                            <label className='mt-3'>Select Price Tag : </label>
                                            <select
                                                required
                                                className="form-control mb-0"
                                                name="addInCategory"
                                                id="addInCategory"
                                                onChange={(e) => {
                                                    try {
                                                        var data = JSON.parse(e.target.value)
                                                        this.setState(
                                                            {
                                                                priceTagToUpdate: data.id,
                                                                updatedLowPriceTag: data.lowPrice,
                                                                updatedHighPriceTag: data.highPrice
                                                            }
                                                        )
                                                    } catch (error) {
                                                        console.log(error);
                                                    }
                                                }}
                                            >
                                                {
                                                    this.state.priceTagData ?
                                                        this.state.priceTagData.map((priceTagObj, idx) => {
                                                            return <option key={idx} value={JSON.stringify(priceTagObj)}>₹{priceTagObj.lowPrice} - ₹{priceTagObj.highPrice}</option>;
                                                        })
                                                        : ""
                                                }
                                            </select>
                                            <label className='mt-3'>Low Price : </label>
                                            <input style={{ maxWidth: '350px' }} className="form-control" id="updatedLowPrice" name="updatedLowPrice" value={this.state.updatedLowPriceTag} onChange={(e) => this.setState({ updatedLowPriceTag: e.target.value })} required />
                                            <label className='mt-3'>High Price : </label>
                                            <input style={{ maxWidth: '350px' }} className="form-control" id="updatedHighPrice" name="updatedHighPrice" value={this.state.updatedHighPriceTag} onChange={(e) => this.setState({ updatedHighPriceTag: e.target.value })} required />
                                            <button type="submit" className="btn btn-primary mt-3">
                                                Update
                                            </button>
                                        </form>
                                    </div>

                                    <div className="card-body order-datatable lists-custom">
                                        {
                                            this.state.priceTagData ?
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Sr. No.</th>
                                                            <th>Price Tag</th>
                                                            <th>Delete Price Tag</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.priceTagData.map((item, idx) =>
                                                            <tr key={idx}>
                                                                <td>{idx + 1}</td>
                                                                <td>₹{item.lowPrice} - ₹{item.highPrice}</td>
                                                                <td>
                                                                    <a href='#' onClick={(e) => this.deletePriceTagFn(e, item.id)}><i className="fa fa-times" /></a>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                :
                                                <div>
                                                    <p>No Price tag has been added yet.</p>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default PriceTags