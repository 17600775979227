import axios from "axios";

//Create Testimonial Review
export const createReview = (data) => {
    return axios
        .post(process.env.PUBLIC_URL + "/users/create-review", data)
        .then((res) => {
            return res
        })
        .catch(err => {
            return err.response;
        });
};

//Add ShowOff Gallery Image
export const createShowOffGallery = (data) => {
    return axios
        .post(process.env.PUBLIC_URL + "/users/create-showoffgallery", data)
        .then((res) => {
            return res
        })
        .catch(err => {
            return err.response;
        });
};

//Add New Testimonial Images
export const createNewTestimonial = (data) => {
    return axios
        .post(process.env.PUBLIC_URL + "/users/create-new-testimonial", data)
        .then((res) => {
            return res
        })
        .catch(err => {
            return err.response;
        });
};

// Delete deleteShowOffGalleryImage
export const deleteShowOffGalleryImage = (Itemid) => {
    return axios
        .post(process.env.PUBLIC_URL + "/users/delete-showoffgalleryimage", {
            id: Itemid.id,
            path: Itemid.path
        })
        .then((res) => {
            alert("Item Deleted");
            return res
        })
        .catch(err => {
            return err.response;
        });
};

// Delete deleteNewTestimonial
export const deleteNewTestimonial = (Itemid) => {
    return axios
        .post(process.env.PUBLIC_URL + "/users/delete-new-testimonialimage", {
            id: Itemid.id,
            path: Itemid.path
        })
        .then((res) => {
            alert("Item Deleted");
            return res
        })
        .catch(err => {
            return err.response;
        });
};

// Delete Testimonial
export const deleteTestimonial = (Itemid) => {
    return axios
        .post(process.env.PUBLIC_URL + "/users/delete-testimonial", {
            id: Itemid.id,
            path: Itemid.path
        })
        .then((res) => {
            alert("Item Deleted");
            return res
        })
        .catch(err => {
            return err.response;
        });
};